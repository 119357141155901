/* eslint-disable react/destructuring-assignment */
import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Uncheck from '@mui/icons-material/CheckBoxOutlineBlank';
import Check from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';

export function NumberInput(props) {
  const inputMode = props.inputMode ? props.inputMode : 'decimal';
  const inputColor = () => {
    if (props.inputcolor) return props.inputcolor;
    if (props.disabled) return '#f3f5f8';
    return '#fff';
  };

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      placeholder={props.disabled ? '' : props.placeholder}
      sx={{
        ...props.inputsx,
        backgroundColor: inputColor(),
        width: props.width ? props.width : '100%',
      }}
      className="TextFieldBackground"
      variant="outlined"
      autoComplete="off"
      isNumericString
      allowNegative={props.allowNegative ? props.allowNegative : false}
      allowedDecimalSeparators={['.', ',']}
      decimalSeparator="."
      decimalScale={props.decimalScale}
      type="text"
      inputProps={{ inputMode }}
      customInput={TextField}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

export function LabelInputSelect(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
      }}
    >
      <Box sx={{ alignSelf: 'flex-start' }}>{props.label}</Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.input}
        <Box sx={{ flexShrink: 0 }}>{props.select}</Box>
      </Box>
      {props.error}
    </Box>
  );
}

export function LabelInput(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
      }}
    >
      <Box sx={{ alignSelf: 'flex-start' }}>{props.label}</Box>
      {props.input}
      {props.error}
    </Box>
  );
}

export function RadioBoxetteInput(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
      }}
      onClick={props.onFocused}
    >
      <Box sx={{ alignSelf: 'flex-start' }}>{props.label}</Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <IconButton>
          <CheckMark
            checked={props.isselected}
            fontSize="large"
            sx={{ color: props.isselected ? 'primary.main' : 'lightGray' }}
          />
        </IconButton>

        {props.input}
        <Box sx={{ flexShrink: 0 }}>{props.select}</Box>
      </Box>
      {props.error}
    </Box>
  );
}

function CheckMark(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return props.checked ? <Check {...props} /> : <Uncheck {...props} />;
}
