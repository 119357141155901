import React from 'react';
import { MenuStateContext, MenuDispatchContext } from './MenuContext';

export default function useMenu() {
  const state = React.useContext(MenuStateContext);
  const dispatch = React.useContext(MenuDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return { ...state, ...dispatch };
}
