import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import Point from './Point';

export default function VSOPCard({ loadingStatus, handleCheckout }) {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();

  return (
    <Box sx={{ position: 'relative' }}>
      {/* <Box sx={{
        position: 'absolute',
        top: -17,
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: 1,
        p: 0.5,
        width: 100,
        backgroundColor: 'ourOrange.main',
        zIndex: 1,
      }}
      >
        <Typography sx={{ fontWeight: 'bold', color: 'white' }} variant="caption">
          {t('subscriptions.most_popular')}
        </Typography>
      </Box> */}
      <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 300,
        height: { xs: '', md: 550 },
        pb: 3,
      }}
      >
        <Box sx={{
          width: '100%',
          backgroundColor: 'primary.main',
          borderRadius: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Typography sx={{ fontWeight: 'bold', color: 'white', mb: 2 }} variant="h5"> VSOP </Typography>
          <Typography sx={{ color: 'white' }}>
            {t('subscriptions.on_quotation')}
          </Typography>
          <Typography variant="caption" sx={{ color: '#d6d6d6' }}>
            {t('flexible_custom')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: 250,
          alignItems: 'flex-start',
          py: 3,
          px: 2,
          flexGrow: 1,
        }}
        >
          <Point text={t('subscriptions.boxettes_access')} />
          <Point
            text={t('subscriptions.inventory')}
            infoButtonText={t('subscriptions.inventory_calculs_info')}
          />
          <Point text={t('subscriptions.orgs_system')} />
          <Point text={t('subscriptions.support')} />
        </Box>
        <Button
          sx={{ textTransform: 'none' }}
          size="large"
          variant="outlined"
          onClick={() => { navigate('/contact'); }}
        >
          {t('user_card.contact_us')}
        </Button>
      </Paper>
    </Box>
  );
}
