import React from 'react';
import {
  dynamicUnitName, readibleValueAndUnit,
} from 'components/boxettes/UnitDefinitionsAndMasks';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ResultCard from 'components/boxettes/ResultCard';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import Box from '@mui/material/Box';

export default function VolumeConversionResults(props) {
  const {
    alcoholConversionResults,
    volumeConversionResults,
    values,
  } = props;

  const isValidData = alcoholConversionResults?.ABVAt20c?.temperature
    && alcoholConversionResults?.ABVAt20c?.value
    && alcoholConversionResults?.ABVAt20c?.unit;

  if (!isValidData) {
    return null;
  }
  return (
    <VolumeConversionDisplay
      alcoholConversionResults={alcoholConversionResults}
      volumeConversionResults={volumeConversionResults}
      values={values}
    />
  );
}

function VolumeConversionDisplay(props) {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();

  const {
    alcoholConversionResults,
    volumeConversionResults,
    values,
  } = props;

  const { ABVAt20c } = alcoholConversionResults;

  const pureAlcoolMenuActions = () => {
    if (resultAvailable()) {
      return [toPureAlcoolWithDryExtract()];
    }
    return undefined;
  };

  const toPureAlcoolWithDryExtract = () => {
    const callback = () => navigate('../pa-conv-with-dry-extract', {
      state: {
        alcoholConversionResults,
        volumeConversionResults,
      },
    });
    return { title: t('boxettes.pa_conversion_with_dry_extract'), callback };
  };

  const volumeAtTempResult = () => {
    if (!resultAvailable()) {
      return '-';
    }
    return readibleValueAndUnit(volumeConversionResults.volumeAtTemp.value, volumeConversionResults.volumeAtTemp.unit);
  };

  const volumePAResult = () => {
    if (!resultAvailable()) {
      return '-';
    }
    return readibleValueAndUnit(
      volumeConversionResults.pureAlcoholVolumeAt20c.value,
      volumeConversionResults.pureAlcoholVolumeAt20c.unit,
    );
  };

  const resultAvailable = () => volumeConversionResults && Object.keys(volumeConversionResults).length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        width: '100%',
        maxWidth: 350,
      }}
    >
      <ResultCard
        title={t('forms.tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: ABVAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(
            ABVAt20c.temperature.unit,
          ),
        })}
        result={
          `${ABVAt20c.value} ${dynamicUnitName(ABVAt20c.unit)}`
        }
        infoButtonIcon={<span style={{ fontSize: 14 }}>[1]</span>}
        infoButtonText={t('forms.real_tav_info_1')}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.correction_factor')}
        result={resultAvailable() ? volumeConversionResults.volumeCorrectionFactor.value : '-'}
      />

      <ResultCard
        title={
          t('forms.volume_at_temp')
          + values.toVolTemp
          + temperatureUnitString(values.temperatureUnit)
        }
        result={volumeAtTempResult()}
      />

      <ResultCard
        title={t('forms.pure_alcohol', {
          referenceTempValue: '20',
          referenceTempUnit: dynamicUnitName(
            values.temperatureUnit,
          ),
        })}
        result={volumePAResult()}
        infoButtonIcon={<span style={{ fontSize: 14 }}>[2]</span>}
        infoButtonText={t('forms.go_to_pa_with_dry_extract')}
        actions={pureAlcoolMenuActions()}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.mass')}
        result={resultAvailable()
          ? readibleValueAndUnit(volumeConversionResults.mass.value, volumeConversionResults.mass.unit)
          : '-'}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.mass_ap')}
        result={resultAvailable()
          ? readibleValueAndUnit(volumeConversionResults.massAP.value, volumeConversionResults.massAP.unit)
          : '-'}
      />

      <Button
        color="secondary"
        variant="contained"
        style={{ marginTop: 15 }}
        onClick={() => {
          navigate('/');
        }}
      >
        <Typography component="span">{t('boxettes.return_menu')}</Typography>
      </Button>
    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}
