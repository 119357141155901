import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CreditLoadingButton from 'components/CreditLoadingButton';
import AlcoholUnitPicker from 'components/units/AlcoholUnitPicker';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import Box from '@mui/material/Box';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import useGlobal from '../../../global-state/store';
import {
  NumberInput,
  RadioBoxetteInput,
} from '../BoxetteInputs';
import validationSchema from './ValidationSchema';
import ResultCard from '../ResultCard';
import { dynamicUnitName, readibleValueAndUnit } from '../UnitDefinitionsAndMasks';
import decimalsMask from './DecimalsMask';

export default function VolumePAWithDryExtractForm(props) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const {
    volumePAConversionWithDryExtract, alcoholConversionResults, volumeConversionResults, freeBoxette,
  } = props;

  const [, globalActions] = useGlobal();
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      alcoholConversionResults.ABVAt20c.unit,
      alcoholConversionResults.ABVAt20c.temperature.unit,
      alcoholConversionResults.DensityAt20c.unit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, densityUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(
        alcoholUnit,
        alcoholConversionResults.ABVAt20c.temperature.unit,
        densityUnit,
        globalState,
      ),
    );
  };

  const boxetteCall = async (values) => {
    try {
      if (Number(alcoholConversionResults.DensityAt20c.temperature.value) !== 20) {
        throw new Error('Density must be at 20°C');
      }
      const result = await volumePAConversionWithDryExtract({
        isRealTAV: values.isRealTAV,
        realTAVValue: values.realTAV,
        realTAVUnit: values.tavUnit,
        dryExtractValue: values.dryExtract,
        densityUnit: values.densityUnit,
        dryExtractPrecision: decimalsMask[values.densityUnit],
        alcoholConversionResults,
        volumeConversionResults,
      });
      return result;
    } catch (e) {
      globalActions.setSnackbarMessage({ message: e.message, severity: 'warning' });
      return undefined;
      // error already reported before normally but
      // still could display something here
    }
  };

  const defaultFormValues = {
    realTAV: '',
    dryExtract: '',
    tavUnit: alcoholConversionResults.ABVAt20c.unit,
    densityUnit: alcoholConversionResults.DensityAt20c.unit,
    isRealTAV: true,
  };

  return (
    <BoxetteForm
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      savedUnits={['densityUnit']}
      defaultFormValues={defaultFormValues}
      formNameInStorage="volume_pa_with_dry_extract_conv_form"
      validationSchema={() => validationSchema(t, alcoholUnitRelateds)}
      freeBoxette={freeBoxette}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
          alcoholConversionResults={alcoholConversionResults}
          volumeConversionResults={volumeConversionResults}
        />
)}
    />
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable, setResults,
  } = React.useContext(BoxetteFormContext);
  const { alcoholConversionResults, setAlcoholUnitRelatedsFrom, volumeConversionResults } = props;
  const { t } = useTranslation();

  const selectIsRealTAV = (setFieldValue) => {
    setResults({});
    formik.setFieldValue('dryExtract', '');
    setFieldValue('isRealTAV', true);
  };
  const selectIsNotRealTAV = (setFieldValue) => {
    setResults({});
    formik.setFieldValue('realTAV', '');
    setFieldValue('isRealTAV', false);
  };

  const pureAlcoolVolumeLabel = () => {
    if (formik.values.isRealTAV) {
      return t('forms.pure_alcohol', {
        referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
        referenceTempUnit: dynamicUnitName(alcoholConversionResults.ABVAt20c.temperature.unit),
      });
    }
    return t('forms.estimate_pure_alcohol', {
      referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
      referenceTempUnit: dynamicUnitName(alcoholConversionResults.ABVAt20c.temperature.unit),
    });
  };

  const pureAlcoolMassLabel = () => {
    if (formik.values.isRealTAV) {
      return t('forms.pure_alcohol_mass');
    }
    return t('forms.estimate_pure_alcohol_mass');
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 600,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.pa_conversion_with_dry_extract')}
      </Typography>

      <ResultCard
        title={t('forms.volume_at_temp')
          + readibleValueAndUnit(
            volumeConversionResults.volumeAtTemp.temperature.value,
            volumeConversionResults.volumeAtTemp.temperature.unit,
          )}
        result={readibleValueAndUnit(
          volumeConversionResults.volumeAtTemp.value,
          volumeConversionResults.volumeAtTemp.unit,
        )}
      />
      <ResultCard
        title={t('forms.mass')}
        result={readibleValueAndUnit(volumeConversionResults.mass.value, volumeConversionResults.mass.unit)}
      />
      <RadioBoxetteInput
        isselected={formik.values.isRealTAV}
        onFocused={() => selectIsRealTAV(formik.setFieldValue)}
        label={(
          <Typography component="span">
            {t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
              alcoholUnit: dynamicUnitName(alcoholConversionResults.ABVAt20c.unit),
              referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
              referenceTempUnit: dynamicUnitName(
                alcoholConversionResults.ABVAt20c.temperature.unit,
              ),
            })}
          </Typography>
        )}
        input={(
          <NumberInput
            inputcolor={formik.values.isRealTAV ? 'white' : '#F2F5F8'}
            id="realTAV"
            name="realTAV"
            value={formik.values.realTAV}
            placeholder="ex: 45.5"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.tavUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.tavUnit);
            }}
          />
        )}
        error={
          formik.touched.realTAV
          && formik.errors.realTAV && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.realTAV}
            </Typography>
          )
        }
        select={(
          <AlcoholUnitPicker
            unit={formik.values.tavUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                newUnit,
                formik.values.densityUnit,
              );
              await formik.setFieldValue('tavUnit', newUnit);
            }}
          />
        )}
      />
      <RadioBoxetteInput
        isselected={!formik.values.isRealTAV}
        onFocused={() => selectIsNotRealTAV(formik.setFieldValue)}
        label={
          <Typography component="span">{t('forms.dry_extract')}</Typography>
        }
        input={(
          <NumberInput
            inputcolor={!formik.values.isRealTAV ? 'white' : '#F2F5F8'}
            id="dryExtract"
            name="dryExtract"
            value={formik.values.dryExtract}
            placeholder="ex: 20.2"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.densityUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.densityUnit);
            }}
          />
        )}
        error={
          formik.touched.dryExtract
          && formik.errors.dryExtract && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.dryExtract}
            </Typography>
          )
        }
        select={(
          <DensityUnitPicker
            unit={formik.values.densityUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                formik.values.tavUnit,
                newUnit,
              );
              await formik.setFieldValue('densityUnit', newUnit);
            }}
          />
        )}
      />
      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={1}
        text={t('forms.calcul')}
      />
      <ResultCard
        title={pureAlcoolVolumeLabel()}
        result={resultsAvailable()
          ? `${results.pureAlcoholVolumeAt20c.value} ${dynamicUnitName(results.pureAlcoholVolumeAt20c.unit)}`
          : '-'}
      />
      <ResultCard
        title={pureAlcoolMassLabel()}
        result={resultsAvailable()
          ? `${results.massAP.value} ${dynamicUnitName(results.massAP.unit)}`
          : '-'}
      />
      {formik.values.isRealTAV === false && (
      <ResultCard
        title={t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
          alcoholUnit: dynamicUnitName(alcoholConversionResults.ABVAt20c.unit),
          referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(
            alcoholConversionResults.ABVAt20c.temperature.unit,
          ),
        })}
        result={resultsAvailable()
          ? readibleValueAndUnit(results.trueAbv.value, results.trueAbv.unit)
          : '-'}
      />
      )}
      <Typography
        sx={{ textAlign: 'left', mt: 3 }}
        component="span"
        variant="caption"
      >
        <Trans
          i18nKey="forms.dry_extract_info_2"
          components={{
            1: <Typography
              component="span"
              variant="caption"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            />,
          }}
        />
      </Typography>

      <Typography
        sx={{ textAlign: 'left', mt: 3 }}
        component="span"
        variant="caption"
      >
        {t('forms.dry_extract_info_1')}
      </Typography>

    </Box>
  );
}
