import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import CreditLoadingButton from 'components/CreditLoadingButton';
import ResultCard from 'components/boxettes/ResultCard';
import Box from '@mui/material/Box';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import EnergeticUnitPicker from 'components/units/EnergeticUnitPicker';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';

export default function EnergeticValueBoxette(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { energeticValue, freeBoxette } = props;

  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(
        alcoholUnit,
        globalState.userUnits.temperatureUnit,
        globalState.userUnits.densityUnit,
        globalState,
      ),
    );
  };

  const boxetteCall = async (values) => {
    const results = await energeticValue(
      {
        abvAt20cValue: values.abvAt20cValue.replace(/,/, '.'),
        sugarConcentrationValue: values.sugarConcentrationValue.replace(/,/, '.'),
        lipidConcentrationValue: values.lipidConcentrationValue.replace(/,/, '.'),
        proteinConcentrationValue: values.proteinConcentrationValue.replace(/,/, '.'),
        bottleVolumeValue: values.bottleVolumeValue
          === '' ? null : values.bottleVolumeValue.replace(/,/, '.'),

        sugarConcentrationUnit: values.sugarConcentrationUnit,
        lipidConcentrationUnit: values.lipidConcentrationUnit,
        proteinConcentrationUnit: values.proteinConcentrationUnit,
        abvAt20cUnit: values.abvAt20cUnit,
        bottleVolumeUnit: values.bottleVolumeUnit,
        energeticValueUnit: `${values.resultEnergyUnit}/${values.resultVolumeUnit}`,
        temperatureUnit: values.temperatureUnit,

        abvAt20cPrecision: decimalsMask[values.abvAt20cUnit],
        sugarConcentrationPrecision: decimalsMask[values.sugarConcentrationUnit],
        lipidConcentrationPrecision: decimalsMask[values.lipidConcentrationUnit],
        proteinConcentrationPrecision: decimalsMask[values.proteinConcentrationUnit],
        bottleVolumeName: values.bottleVolumeName,
      },
    );
    return results;
  };

  // This boxette only is done for TAV at 20°C, could be updated to use other ABV and
  // temperature unit but has to be verified
  const defaultFormValues = {
    withBottle: false,
    sugarConcentrationUnit: 'g / L',
    lipidConcentrationUnit: 'g / L',
    proteinConcentrationUnit: 'g / L',
    abvAt20cUnit: 'TAV',
    bottleVolumeUnit: 'cL',
    temperatureValue: '20',
    temperatureUnit: 'celsius',
    bottleVolumeName: t('forms.bottle'),
    abvAt20cValue: '',
    sugarConcentrationValue: '',
    lipidConcentrationValue: '',
    proteinConcentrationValue: '',
    bottleVolumeValue: '',
    resultEnergyUnit: 'kcal',
    resultVolumeUnit: 'dL',
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      savedUnits={['resultEnergyUnit',
        'resultVolumeUnit',
        'bottleVolumeUnit', 'sugarConcentrationUnit', 'lipidConcentrationUnit', 'proteinConcentrationUnit']}
      defaultFormValues={defaultFormValues}
      formNameInStorage="energetic_value_form"
      validationSchema={() => validationSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
        />
      )}
      freeBoxette={freeBoxette}
    />

  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable, setResults,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 500,
      }}
    >
      <Typography variant="h4" component="span">
        {t('boxettes.energetic_value')}
      </Typography>

      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.total_sugar')}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="sugarConcentrationValue"
            placeholder="ex: 11"
            value={formik.values.sugarConcentrationValue}
            decimalScale={decimalsMask[formik.values.sugarConcentrationUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.sugarConcentrationUnit);
            }}
          />
        )}
        error={formik.touched.sugarConcentrationValue && formik.errors.sugarConcentrationValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.sugarConcentrationValue}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.sugarConcentrationUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('sugarConcentrationUnit', newUnit);
            }}
          />
        )}
      />

      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.tav_at', {
              tempValue: formik.values.temperatureValue,
              tempUnit: dynamicUnitName(formik.values.temperatureUnit),
            })}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="abvAt20cValue"
            placeholder="ex: 11"
            value={formik.values.abvAt20cValue}
            decimalScale={decimalsMask[formik.values.abvAt20cUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.abvAt20cUnit);
            }}
          />
        )}
        error={formik.touched.abvAt20cValue && formik.errors.abvAt20cValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.abvAt20cValue}
        </Typography>
        )}
        select={(
          <Typography component="span">
            {dynamicUnitName(formik.values.abvAt20cUnit)}
          </Typography>
        )}
      />

      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.fat')}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="lipidConcentrationValue"
            placeholder="ex: 5"
            value={formik.values.lipidConcentrationValue}
            decimalScale={decimalsMask[formik.values.lipidConcentrationUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.lipidConcentrationUnit);
            }}
          />
        )}
        error={formik.touched.lipidConcentrationValue && formik.errors.lipidConcentrationValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.lipidConcentrationValue}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.lipidConcentrationUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('lipidConcentrationUnit', newUnit);
            }}
          />
        )}
      />

      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.protein')}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="proteinConcentrationValue"
            placeholder="ex: 11"
            value={formik.values.proteinConcentrationValue}
            decimalScale={decimalsMask[formik.values.proteinConcentrationUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.proteinConcentrationUnit);
            }}
          />
        )}
        error={formik.touched.proteinConcentrationValue && formik.errors.proteinConcentrationValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.proteinConcentrationValue}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.proteinConcentrationUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('proteinConcentrationUnit', newUnit);
            }}
          />
        )}
      />

      <Typography variant="h6" component="span">
        {t('forms.result_units_select')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          width: '100%',
        }}
      >
        <EnergeticUnitPicker
          value={formik.values.resultEnergyUnit}
          setunit={async (newUnit) => {
            await formik.setFieldValue('resultEnergyUnit', newUnit);
            setResults({});
          }}
        />
        <Typography variant="h6" component="span">
          /
        </Typography>
        <VolumeUnitPicker
          withBottle
          unit={formik.values.resultVolumeUnit}
          setUnit={async (newUnit) => {
            await formik.setFieldValue('resultVolumeUnit', newUnit);
            if (newUnit === t('forms.bottle')) {
              formik.setFieldValue('withBottle', true);
            } else {
              formik.setFieldValue('withBottle', false);
            }
            setResults({});
          }}
        />
      </Box>

      {formik.values.withBottle && (
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.bottle_volume')}</Typography>
        }
        input={(
          <NumberInput
            id="bottleVolumeValue"
            name="bottleVolumeValue"
            value={formik.values.bottleVolumeValue}
            placeholder="ex: 75"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.bottleVolumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.bottleVolumeUnit);
            }}
          />
        )}
        error={
          formik.touched.bottleVolumeValue
          && formik.errors.bottleVolumeValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.bottleVolumeValue}
            </Typography>
          )
        }
        select={(
          <VolumeUnitPicker
            unit={formik.values.bottleVolumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('bottleVolumeUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />
      )}

      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={0}
        text={t('forms.calcul')}
      />
      <ResultCard
        title={t('boxettes.energetic_value')}
        result={resultsAvailable()
          ? `${results.energeticValue.value} ${dynamicUnitName(results.energeticValue.unit)}`
          : '-'}
      />
      <Typography align="left" component="i">
        {t('energeticValue.info', { joinArrays: '' })}
      </Typography>
    </Box>
  );
}
