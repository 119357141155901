import React, { useState } from 'react';
import {
  Box, Typography, Select, MenuItem,
} from '@mui/material';
import { formatNumber } from '../utils/utils';

export default function SugarMassWithUnit({ locale, value, unit }) {
  const [displayUnit, setDisplayUnit] = useState(unit);
  const displayValue = displayUnit === 'kg' ? value / 1000 : value;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body1">
        {formatNumber(locale, displayValue)}
      </Typography>
      <Select
        value={displayUnit}
        onChange={(e) => setDisplayUnit(e.target.value)}
        size="small"
        sx={{ width: 65 }}
      >
        <MenuItem value="g">g</MenuItem>
        <MenuItem value="kg">kg</MenuItem>
      </Select>
    </Box>
  );
}
