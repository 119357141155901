import React from 'react';
import useGlobal from 'global-state/store';
import CreditLoadingButton from 'components/CreditLoadingButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import TemperatureUnitPicker from 'components/units/TemperatureUnitPicker';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import Box from '@mui/material/Box';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import ValidationSchema from './ValidationSchema';
import VolumeConversionResults from './VolumePAConversionResults';
import decimalsMask from './DecimalsMask';

export default function VolumePAConversionBoxette(props) {
  const { t } = useTranslation();
  const {
    alcoholConversionResults,
    volumePAConversionToTemp,
    freeBoxette,
  } = props;
  const [globalState] = useGlobal();

  const boxetteCall = async (values) => {
    const results = await volumePAConversionToTemp(
      values.volumeValue.replace(/,/, '.'),
      values.temperatureValue.replace(/,/, '.'),
      alcoholConversionResults.ABVAt20c.value,
      values.volumeUnit,
      values.temperatureUnit,
      alcoholConversionResults.ABVAt20c.unit,
      decimalsMask[values.volumeUnit],
      decimalsMask[values.temperatureUnit],
      alcoholConversionResults.ABVAt20c.precision,
    );
    return results;
  };

  const defaultFormValues = {
    volumeValue: '',
    temperatureValue: globalState.userUnits.temperatureValue,
    volumeUnit: globalState.userUnits.volumeUnit,
    temperatureUnit: globalState.userUnits.temperatureUnit,
  };

  return (
    <BoxetteForm
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      savedUnits={['volumeUnit']}
      formNameInStorage="volume_pa_conv_form"
      validationSchema={() => ValidationSchema(t)}
      form={<InnerForm alcoholConversionResults={alcoholConversionResults} />}
      freeBoxette={freeBoxette}
    />
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, setResults,
  } = React.useContext(BoxetteFormContext);
  const { alcoholConversionResults } = props;
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 3,
          maxWidth: { xs: '100%', sm: 350 },
        }}
      >
        <LabelInputSelect
          label={(
            <Typography component="span">
              {t('forms.volume_PA_value')}
            </Typography>
                )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="volumeValue"
              placeholder="ex: 4.567"
              value={formik.values.volumeValue}
              decimalScale={decimalsMask[formik.values.volumeUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.volumeUnit);
              }}
            />
                )}
          select={(
            <VolumeUnitPicker
              unit={formik.values.volumeUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('volumeUnit', newUnit);
                setResults({});
              }}
            />
                )}
          error={
                  formik.touched.volumeValue
                  && formik.errors.volumeValue && (
                    <Typography style={{ fontSize: 10, color: 'red' }}>
                      {formik.errors.volumeValue}
                    </Typography>
                  )
                }
        />

        <LabelInputSelect
          label={(
            <Typography component="span">
              {t('forms.simple_measured_temp')}
            </Typography>
                )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="temperatureValue"
              placeholder="ex: 20"
              value={formik.values.temperatureValue}
              decimalScale={decimalsMask[formik.values.temperatureUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.temperatureUnit);
              }}
            />
                )}
          select={(
            <TemperatureUnitPicker
              unit={formik.values.temperatureUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('temperatureUnit', newUnit);
              }}
              allowNegative
            />
                )}
          error={
                  formik.touched.temperatureValue
                  && formik.errors.temperatureValue && (
                    <Typography
                      component="span"
                      style={{ fontSize: 13, color: 'red' }}
                    >
                      {formik.errors.temperatureValue}
                    </Typography>
                  )
                }
        />

        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={1}
          text={t('forms.calcul')}
        />
        {' '}
        <Typography
          sx={{ textAlign: 'left' }}
          component="span"
          variant="caption"
        >
          [1]
          {' '}
          {t('forms.real_tav_info_1')}
          {'\n'}
          {t('forms.real_tav_info_1_else')}
        </Typography>
      </Box>
      <VolumeConversionResults
        volumeConversionResults={results}
        alcoholConversionResults={alcoholConversionResults}
        values={formik.values}
      />

    </Box>
  );
}
