import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Divider, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Point from './Point';

export default function FreeCard(props) {
  const { t } = useTranslation();
  return (
    <Paper sx={{
      py: 3,
      px: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      height: { xs: '', md: 550 },
      width: 250,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 105,
      }}
      >
        <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6"> Free </Typography>
        <Typography>
          <span>
            {`0€ / ${t('month')}`}
          </span>
        </Typography>
      </Box>
      <Divider />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignItems: 'flex-start',
        pt: 2,
      }}
      >
        <Point text={t('subscriptions.boxettes_essential')} />
        <Point text={t('subscriptions.boxettes_essential_test')} />
      </Box>
    </Paper>
  );
}
