import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import MassVolumeForm from './MassVolumeForm';

export default function MassVolumeBoxette(props) {
  const navigate = useLocalizedNavigate();
  const location = useLocation();
  const { convertMassToVolumeFromTemp } = props;
  const { t } = useTranslation();

  const [alcoholConversionResults] = useState(
    location.state?.alcoholConversionResults,
  );

  useEffect(() => {
    if (!alcoholConversionResults) {
      navigate('../mass-vol-alco-conv', {
        replace: true,
        state: location.state,
      });
    }
  }, [navigate, alcoholConversionResults, location.state]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.mass_vol_conversion')}
      </Typography>
      <FormOrLoader
        convertMassToVolumeFromTemp={convertMassToVolumeFromTemp}
        alcoholConversionResults={alcoholConversionResults}
      />
    </Box>
  );
}

function FormOrLoader(props) {
  const { convertMassToVolumeFromTemp, alcoholConversionResults } = props;

  if (alcoholConversionResults) {
    return (
      <MassVolumeForm
        convertMassToVolumeFromTemp={convertMassToVolumeFromTemp}
        alcoholConversionResults={alcoholConversionResults}
      />
    );
  } return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
