/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlcoConversionTAVorMV from 'components/boxettes/alco-conversion/AlcoConversionTAVorMV';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import ForwardButton from '../alco-conversion/ForwardButton';

export default function MassVolumeAlcoBoxette(props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.mass_vol_conversion')}
      </Typography>
      <AlcoConversionTAVorMV actions={<BoxetteActions />} {...props} />
    </Box>
  );
}

function BoxetteActions(props) {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const location = useLocation();
  const {
    formikValues, formikSubmitForm, calculating, formikIsValid,
  } = props;
  return (
    <ForwardButton
      calculating={calculating}
      isValid={formikIsValid}
      text={t('forms.toward_mass_volume_conversion')}
      creditsCost={formikValues.alcoholUnit === 'TAV'
          && formikValues.measuredTemp === '20.0'
          && formikValues.temperatureUnit === 'celsius' ? 0 : 1}
      handleClick={async () => {
        const results = await formikSubmitForm();
        if (formikIsValid && results !== undefined && Object.keys(results).length > 0) {
          sessionStorage.removeItem('current_form:mass_volume_form');
          navigate('../mass-vol-conv', {
            state: { alcoholConversionResults: results, ...location.state },
          });
        }
      }}
    />
  );
}
