import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from 'i18n/i18next';

export default function LanguageGuard({ children }) {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    const firstPart = pathParts[0];

    if (!supportedLanguages.includes(firstPart)) {
      // No language prefix, redirect
      // const newPath = location.pathname.startsWith('/')
      //   ? location.pathname.slice(1)
      //   : location.pathname;
      // console.log('newPath', newPath);
      // navigate(`/${i18n.language}/${newPath}${location.search}`, { replace: true });
    } else if (firstPart !== i18n.language) {
      // Language prefix doesn't match current language, update i18n
      i18n.changeLanguage(firstPart);
    }
  }, [location, i18n]);

  return children;
}
