/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router';
import BoxettesMenu from 'components/boxettes/BoxettesMenu';
import Subscriptions from 'components/subscriptions/Subscriptions';
import MyAccount from 'components/account/MyAccount';
import AboutUs from 'components/about-us/AboutUs';
import Experts from 'components/about-us/Experts';
import Tips from 'components/tips/Tips';
import Admin from 'components/admin/Admin';
import Issuers from 'components/admin/issuers-and-codes/Issuers';
import DeeplinksHandler from 'components/deeplinks/DeeplinksHandler';
import Automated from 'components/automated/Automated';
import PDFView from 'components/PDFView';
import SignInWrapper from 'components/auth/SignInWrapper';
import ContactWrapper from 'components/contact/ContactWrapper';
import cguFR from 'files/CGU.pdf';
import cgvFR from 'files/CGV.pdf';
import cgucgvFR from 'files/CGU+CGV.pdf';
import oimlPublication from 'files/publi.pdf';
import BoxettesApiCalls from 'components/boxettes/BoxettesApiCalls';
import AlcoConversionBoxette from 'components/boxettes/alco-conversion/AlcoConversionBoxette';
import VolumeConversionBoxette from 'components/boxettes/vol-conversion/VolumeConversionBoxette';
import VolumePAConversionBoxette from 'components/boxettes/vol-pa-conversion/VolumePAConversionBoxette';
import VolumeConversionAlcoBoxette from 'components/boxettes/vol-conversion/VolumeConversionAlcoBoxette';
import MassVolumeAlcoBoxette from 'components/boxettes/mass-vol-conversion/MassVolumeAlcoBoxette';
import MassVolumeBoxette from 'components/boxettes/mass-vol-conversion/MassVolumeBoxette';
import ReductionImprovementBoxette from 'components/boxettes/reduction-improvement/ReductionImprovementBoxette';
import DryExtractBoxette from 'components/boxettes/dryExtractObscuration/DryExtractBoxette';
import VolumePAWithDryExtractBoxette from 'components/boxettes/vol-pa-with-dry-extract/VolumePAWithDryExtractBoxette';
import UllagingBoxette from 'components/boxettes/ullaging/Ullaging';
import IngredientAdjustmentsBoxette from 'components/boxettes/ingredientAdjustments/IngredientAdjustmentsBoxette';
import EbulliometerBoxette from 'components/boxettes/ebulliometer/EbulliometerBoxette';
import AlcoConversionToUSBoxette from 'components/boxettes/alco-to-us-conversion/AlcoConversionToUSBoxette';
import EnergeticValueBoxette from 'components/boxettes/energeticValue/EnergeticValueBoxette';
import FlashPointBoxette from 'components/boxettes/flash-point/FlashPointBoxette';
import ActiveSO2Boxette from 'components/boxettes/so2-conversions/ActiveSO2Boxette';
import ActiveSO2Rectify from 'components/boxettes/so2-conversions/ActiveSO2Rectify';
import FreeSO2Boxette from 'components/boxettes/so2-conversions/FreeSO2Boxette';
import MutageBoxette from 'components/boxettes/mutage/MutageBoxette';
import UnitConversions from 'components/boxettes/unit-conversions/UnitConversions';
import RecipeCalculationsTrueAbvBoxette
  from 'components/boxettes/recipe-calculations-true-abv/RecipeCalculationsTrueAbvBoxette';
import { supportedLanguages } from '../i18n/i18next';
import ErrorPage from './ErrorPage';
import { getLocalizedPath } from './navigation';
import { legacyRedirects, getRedirectPath } from './redirects';

// Base routes that don't require special handling
const baseRoutes = [
  {
    path: '',
    element: <BoxettesMenu />,
  },
  {
    path: 'subscriptions',
    element: <Subscriptions />,
  },
  {
    path: 'account',
    element: <MyAccount />,
  },
  {
    path: 'about-us',
    element: <AboutUs />,
  },
  {
    path: 'experts',
    element: <Experts />,
  },
  {
    path: 'tips',
    element: <Tips />,
  },
  {
    path: 'automated',
    element: <Automated />,
  },
  {
    path: 'admin',
    element: <Admin />,
  },
  {
    path: 'issuers',
    element: <Issuers />,
  },
  {
    path: 'deeplink',
    element: <DeeplinksHandler />,
  },
];

// PDF routes
const pdfRoutes = [
  {
    path: 'cgu',
    element: <PDFView path={cguFR} />,
  },
  {
    path: 'cgv',
    element: <PDFView path={cgvFR} />,
  },
  {
    path: 'cgu-cgv',
    element: <PDFView path={cgucgvFR} />,
  },
  {
    path: 'oiml-publication',
    element: <PDFView path={oimlPublication} />,
  },
];

// Free boxettes that don't require grants
const freeBoxettes = [
  {
    path: 'alco-conv',
    element: <AlcoConversionBoxette />,
  },
  {
    path: 'vol-conv',
    element: <VolumeConversionBoxette />,
  },
  {
    path: 'vol-pa-conv',
    element: <VolumePAConversionBoxette />,
  },
  {
    path: 'vol-alco-conv',
    element: <VolumeConversionAlcoBoxette />,
  },
  {
    path: 'ebulliometer',
    element: <EbulliometerBoxette />,
  },
  {
    path: 'unit-conversions',
    element: <UnitConversions />,
  },
  {
    path: 'recipe-calculations',
    element: <RecipeCalculationsTrueAbvBoxette />,
  },
  {
    path: 'energetic-value',
    element: <EnergeticValueBoxette />,
  },
  {
    path: 'flash-point',
    element: <FlashPointBoxette />,
  },
  {
    path: 'active-so2',
    element: <ActiveSO2Boxette />,
  },
  {
    path: 'active-so2/rectify',
    element: <ActiveSO2Rectify />,
  },
  {
    path: 'free-so2',
    element: <FreeSO2Boxette />,
  },
  {
    path: 'reduction-improvement',
    element: <ReductionImprovementBoxette />,
  },
];

// Premium boxettes that require grants for an unlimited access
const premiumBoxettes = [
  {
    path: 'ullaging',
    element: <UllagingBoxette />,
  },
  {
    path: 'mass-vol-alco-conv',
    element: <MassVolumeAlcoBoxette />,
  },
  {
    path: 'mass-vol-conv',
    element: <MassVolumeBoxette />,
  },
  {
    path: 'mutage',
    element: <MutageBoxette />,
  },
  {
    path: 'pa-conv-with-dry-extract',
    element: <VolumePAWithDryExtractBoxette />,
  },
  {
    path: 'alco-to-us-conv',
    element: <AlcoConversionToUSBoxette />,
  },
  {
    path: 'ingredient-adjustments',
    element: <IngredientAdjustmentsBoxette />,
  },
  {
    path: 'dryExtractObscuration',
    element: <DryExtractBoxette />,
  },
];

// Function to create the routes configuration
export default function createRoutes({
  defaultLanguage,
  hasRequiredGrants,
  t,
  contactProps,
  signInProps,
  privacyPolicyFile,
}) {
  // Helper function to get translated path without language prefix
  const getTranslatedPath = (path, lang) => {
    const fullPath = getLocalizedPath(path, true);
    return fullPath.split('/').slice(2).join('/');
  };

  const wrappedFreeBoxettes = freeBoxettes.map((route) => ({
    ...route,
    path: getTranslatedPath(route.path),
    element: <BoxettesApiCalls
      boxette={route.element}
      freeBoxette
    />,
  }));

  const wrappedPremiumBoxettes = premiumBoxettes.map((route) => ({
    ...route,
    path: getTranslatedPath(route.path),
    element: <BoxettesApiCalls
      boxette={route.element}
      freeBoxette={false}
    />,
  }));

  const wrappedBoxettes = [...wrappedFreeBoxettes, ...wrappedPremiumBoxettes];

  // Add translations to base routes
  const extendedBaseRoutes = [
    ...baseRoutes.map((route) => ({
      ...route,
      path: getTranslatedPath(route.path),
    })),
    ...pdfRoutes.map((route) => ({
      ...route,
      path: getTranslatedPath(route.path),
    })),
    {
      path: getTranslatedPath('privacy-policy'),
      element: <PDFView path={privacyPolicyFile} />,
    },
    {
      path: getTranslatedPath('contact'),
      element: <ContactWrapper {...contactProps} />,
    },
    {
      path: getTranslatedPath('signin'),
      element: <SignInWrapper {...signInProps} />,
    },
  ];

  // Create language-specific routes
  const languageRoutes = supportedLanguages.map((lang) => ({
    path: lang,
    errorElement: <ErrorPage />,
    children: [
      ...extendedBaseRoutes,
      ...wrappedBoxettes,
    ],
  }));

  // Create legacy redirect routes
  const legacyRoutes = Object.keys(legacyRedirects).map((oldPath) => ({
    path: oldPath.replace('/', ''), // Remove leading slash as it's added by the router
    element: <Navigate replace to={getRedirectPath(oldPath, defaultLanguage)} />,
  }));

  // console.log('languageRoutes', languageRoutes);

  return [
    {
      index: true,
      element: <Navigate to={`/${defaultLanguage}/`} replace />,
    },
    ...languageRoutes,
    ...legacyRoutes,
    {
      path: '*',
      element: <ErrorPage />,
    },
  ];
}
