import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, MenuItem, FormControl,
  Tooltip,
  IconButton,
} from '@mui/material';
import useGlobal from 'global-state/store';
import InfoIcon from '@mui/icons-material/Info';
import { supportedLanguages } from 'i18n/i18next';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
// import { useLocation } from 'react-router';
// import { translatePathSegment } from '../../routing/navigation';

export default function LanguageSelectToggle() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(localStorage.getItem('saved_locale') || t('locale'));
  const [, globalActions] = useGlobal();
  const navigate = useLocalizedNavigate();
  // const location = useLocation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  const handleLocaleChange = (event) => {
    const newLocale = event.target.value;
    setLocale(newLocale);
    i18n.changeLanguage(newLocale);
    localStorage.setItem('saved_locale', newLocale);

    // Get current path without language prefix
    // const pathParts = location.pathname.split('/').slice(2);

    // Translate each path segment and join them
    // const translatedPath = pathParts
    //   .map((segment) => translatePathSegment(segment))
    //   .filter(Boolean)
    //   .join('/');

    // Add locale prefix and navigate
    navigate('/');

    if (newLocale !== 'en' && newLocale !== 'fr') {
      const warningMessage = t('translation_warning');
      globalActions.setSnackbarMessage(({ message: warningMessage, severity: 'info' }));
    }
  };

  return (
    <FormControl variant="outlined">
      <Select
        size="small"
        id="language-select"
        value={locale}
        onChange={handleLocaleChange}
        sx={{ with: 100, height: 33 }}
      >
        {supportedLanguages.map((lang) => (
          <MenuItem key={lang} value={lang}>
            {lang.toUpperCase()}
            {lang !== 'en' && lang !== 'fr' && <TranslationWarning />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function TranslationWarning() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('translation_warning')}>
      <IconButton sx={{ width: 12 }} color="ourOrange">
        <InfoIcon sx={{ fontSize: 12 }} />
      </IconButton>
    </Tooltip>
  );
}
