import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteForm } from 'components/boxettes/BoxetteForm';
import useMenu from 'menu-actions/useMenu';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';
import TAVsForm from './TAVsForm';
import VolumeForm from './VolumeForm';
import Results from './Results';

export default function ReductionImprovementBoxette(props) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const { rectifyExistingProduct, prepareProduct, freeBoxette } = props;
  const [page, setPage] = useState('tavs');
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = (alcoholUnit) => {
    setAlcoholUnitRelateds(alcoholUnitRelatedsFrom(
      alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ));
  };

  const boxetteCall = async (values) => {
    let result;
    if (!values.isPreparation) {
      result = await rectifyExistingProduct({
        abvStartValue: values.tavStart,
        abvAddedValue: values.tavAdded,
        abvFinalValue: values.tavFinal,
        volumeValue: values.volumeValue,
        abvUnit: values.alcoholUnit,
        volumeUnit: values.volumeUnit,
        abvPrecision: decimalsMask[values.alcoholUnit],
        volumePrecision: decimalsMask[values.volumeUnit],
      });
    } else {
      result = await prepareProduct(
        {
          abvStartValue: values.tavStart,
          abvAddedValue: values.tavAdded,
          abvFinalValue: values.tavFinal,
          volumeValue: values.volumeValue,
          abvUnit: values.alcoholUnit,
          volumeUnit: values.volumeUnit,
          abvPrecision: decimalsMask[values.alcoholUnit],
          volumePrecision: decimalsMask[values.volumeUnit],
        },
      );
    }
    setPage('results');
    return result;
  };

  const defaultFormValues = {
    tavStart: '',
    tavAdded: '',
    tavFinal: '',
    alcoholUnit: globalState.userUnits.alcoholUnit,
    isPreparation: false,
    volumeValue: '',
    volumeUnit: globalState.userUnits.volumeUnit,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <BoxetteForm
        applyHistoryForResults
        decimalsMask={decimalsMask}
        boxetteCall={boxetteCall}
        savedUnits={['volumeUnit']}
        defaultFormValues={defaultFormValues}
        formNameInStorage="reduction_improvement_tavs_form"
        validationSchema={() => validationSchema(t, alcoholUnitRelateds)}
        form={(
          <InnerForm
            setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
            page={page}
            setPage={setPage}
          />
        )}
        freeBoxette={freeBoxette}
      />
      <Typography align="left" component="i" variant="subtitle1">
        {t('reduction_improvement.info', { joinArrays: '' })}
      </Typography>
    </Box>
  );
}

function InnerForm(props) {
  const { setreturnAction } = useMenu();
  const navigate = useLocalizedNavigate();
  const { page, setPage } = props;

  useEffect(() => {
    setreturnAction(() => {
      if (page === 'tavs') {
        return () => navigate(-1);
      } if (page === 'volume') {
        return () => setPage('tavs');
      }
      return () => setPage('volume');
    });
  }, [navigate, page, setPage, setreturnAction]);

  return <PageContent page={page} setPage={setPage} />;
}

function PageContent(props) {
  const { page, setPage } = props;
  if (page === 'tavs') {
    return <TAVsForm setPage={setPage} />;
  } if (page === 'volume') {
    return <VolumeForm setPage={setPage} />;
  }
  return <Results setPage={setPage} />;
}
