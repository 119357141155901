import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import alcoconvimage from 'files/conversion-alco-vol.jpg';
import volconvimage from 'files/barrel.jpg';
import massvolconvimage from 'files/conversion-alco-mass.jpg';
import ingredientAdjustmentsImage from 'files/ingredient_adjustments.jpg';
import ebulliometerImage from 'files/ebulliometre_electronique.jpg';
import mutageImage from 'files/mutage.jpg';
import dryExtractObscurationImage from 'files/dryExtractObscuration.jpg';
import ullagingimage from 'files/barrel_ullaging.jpg';
import alcotousconvimage from 'files/alco-to-us-conv.jpg';
import energecticValueImage from 'files/energeticValue.jpg';
import cognacImage from 'files/cognac.jpg';
import flashPointImage from 'files/flashpoint.jpg';
import unitConversions from 'files/unit-conversions.jpeg';
import { useSigninCheck } from 'reactfire';
import { Link } from 'react-router';
import Box from '@mui/material/Box';
import OIMLConformity from 'components/OIMLConformity';
import VerifyEmail from 'components/account/VerifyEmail';
import useMenu from 'menu-actions/useMenu';
import so2Image from 'files/so2Image.jpg';
import so2LibreImage from 'files/so2libre.jpg';
import useGlobal from 'global-state/store';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getLocalizedPath } from 'routing/navigation';
import liquorImage from 'files/liquor.avif';
import BoxettesSearch from 'components/faq/BoxettesSearch';
import BoxetteCard from './BoxetteCard';
import FavoriteBoxettes from './FavoriteBoxettes';
import SparkleButton from '../common/SparkleButton';

export default function BoxettesMenu(props) {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const { dispatchMenuActions } = useMenu();
  const [globalState] = useGlobal();

  const cleanupMenuActions = useCallback(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  const cleanupMenuActionsAndCurrentForm = useCallback(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes('current_form:')) {
        sessionStorage.removeItem(key);
      }
    });
    cleanupMenuActions();
  }, [cleanupMenuActions]);

  useEffect(() => {
    cleanupMenuActionsAndCurrentForm();
  }, [cleanupMenuActionsAndCurrentForm]);

  const initialBoxettes = () => {
    const savedFavsLocal = localStorage.getItem('favorite_boxettes');
    const savedFavs = savedFavsLocal === null ? [] : JSON.parse(savedFavsLocal);
    return {
      'alco-conv': {
        route: '/alco-conv',
        title: t('boxettes.alco_conversion'),
        image: alcoconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('alco-conv'),
        free: true,
      },
      'vol-conv': {
        route: '/vol-conv',
        title: t('boxettes.vol_conversion'),
        image: volconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('vol-conv'),
        free: true,
      },
      ullaging: {
        route: '/ullaging',
        title: t('boxettes.ullaging'),
        image: ullagingimage,
        category: 'production',
        favorite: savedFavs.includes('ullaging'),
        free: false,
      },
      'mass-vol-conv': {
        route: '/mass-vol-conv',
        title: t('boxettes.mass_vol_conversion'),
        image: massvolconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('mass-vol-conv'),
        free: false,
      },
      'alco-to-us-conv': {
        route: '/alco-to-us-conv',
        title: t('boxettes.alco_us_conversion'),
        image: alcotousconvimage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('alco-to-us-conv'),
        free: false,
      },
      dryExtractObscuration: {
        route: '/dryExtractObscuration',
        title: t('boxettes.dryExtractObscuration'),
        image: dryExtractObscurationImage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('dryExtractObscuration'),
        free: false,
      },
      ebulliometer: {
        route: '/ebulliometer',
        title: t('boxettes.ebulliometer'),
        image: ebulliometerImage,
        category: 'alcoholometry',
        favorite: savedFavs.includes('ebulliometer'),
        free: true,
      },
      'reduction-improvement': {
        route: '/reduction-improvement',
        title: t('boxettes.reduction_improvement'),
        image: cognacImage,
        category: 'production',
        favorite: savedFavs.includes('reduction-improvement'),
        free: true,
      },
      'unit-conversions': {
        route: '/unit-conversions',
        title: t('boxettes.unit_conversions'),
        image: unitConversions,
        category: 'production',
        favorite: savedFavs.includes('unit-conversions'),
        free: true,
      },
      'ingredient-adjustments': {
        route: '/ingredient-adjustments',
        title: t('boxettes.ingredient_adjustments'),
        image: ingredientAdjustmentsImage,
        category: 'production',
        favorite: savedFavs.includes('ingredient-adjustments'),
        free: false,
      },
      'energetic-value': {
        route: '/energetic-value',
        title: t('boxettes.energetic_value'),
        image: energecticValueImage,
        category: 'production',
        favorite: savedFavs.includes('energetic-value'),
        free: true,
      },
      'flash-point': {
        route: '/flash-point',
        title: t('boxettes.flash_point'),
        image: flashPointImage,
        category: 'production',
        favorite: savedFavs.includes('flash-point'),
        free: true,
      },
      'active-so2': {
        route: '/active-so2',
        title: t('boxettes.active_so2'),
        image: so2Image,
        category: 'production',
        favorite: savedFavs.includes('active-so2'),
        free: true,
      },
      'free-so2': {
        route: '/free-so2',
        title: t('boxettes.free_so2'),
        image: so2LibreImage,
        category: 'production',
        favorite: savedFavs.includes('free-so2'),
        free: true,
      },
      mutage: {
        route: '/mutage',
        title: t('boxettes.mutage'),
        image: mutageImage,
        category: 'production',
        favorite: savedFavs.includes('mutage'),
        free: false,
      },
      'recipe-calculations': {
        route: '/recipe-calculations',
        title: t('boxettes.recipe_calculations'),
        image: liquorImage,
        category: 'production',
        favorite: savedFavs.includes('recipe-calculations'),
        free: true,
        beta: true,
      },
    };
  };

  const [boxettes, dispatchBoxettes] = React.useReducer(reducerBoxettes, initialBoxettes());

  function reducerBoxettes(currentState, dispatch) {
    const finalBoxettes = { ...currentState };
    const savedFavsLocal = localStorage.getItem('favorite_boxettes');
    const favoriteBoxettes = savedFavsLocal === null ? [] : JSON.parse(savedFavsLocal);
    const boxetteName = dispatch.name.startsWith('/') ? dispatch.name.slice(1) : dispatch.name;

    switch (dispatch.type) {
      case 'addToFavorites':
        finalBoxettes[boxetteName].favorite = true;
        favoriteBoxettes.push(boxetteName);
        localStorage.setItem('favorite_boxettes', JSON.stringify(favoriteBoxettes));
        return finalBoxettes;
      case 'removeFromFavorites':
        finalBoxettes[boxetteName].favorite = false;
        localStorage.setItem(
          'favorite_boxettes',
          JSON.stringify(favoriteBoxettes.filter((el) => el !== boxetteName)),
        );
        return finalBoxettes;
      case 'clear':
        localStorage.setItem('favorite_boxettes', JSON.stringify([]));
        return [];
      default:
        throw new Error();
    }
  }

  const boxettesActivated = (disabled) => !disabled
    && (!signInCheckResult?.signedIn || signInCheckResult?.user?.emailVerified);

  const showEmailVerify = () => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === false;

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  const boxettesAvailable = hasRequiredGrants
    ? Object.keys(boxettes)
    : Object.keys(boxettes).filter((key) => boxettes[key].free === true);
  const boxettesNotAvailable = hasRequiredGrants
    ? []
    : Object.keys(boxettes).filter((key) => boxettes[key].free === false);

  return (
    <Box sx={{
      display: 'flex',
      flexFlow: 'column',
      gap: { xs: 1, sm: 2 },
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1500,
    }}
    >
      <BoxettesSearch />
      {showEmailVerify() && <VerifyEmail />}
      <FavoriteBoxettes boxettes={boxettes} dispatchBoxettes={dispatchBoxettes} />
      <Box sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        gap: { xs: 1, sm: 2 },
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
      }}
      >
        {boxettesAvailable.map((key) => (
          <BoxetteCard
            key={key}
            title={boxettes[key].title}
            image={boxettes[key].image}
            activated={boxettesActivated(boxettes[key].disabled)}
            route={boxettes[key].route}
            dispatchBoxettes={dispatchBoxettes}
            isFavorite={boxettes[key].favorite}
            free={boxettes[key].free}
            beta={boxettes[key].beta}
          />
        ))}
      </Box>

      {boxettesNotAvailable.length > 0 && (
        <Box sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: { xs: 1, sm: 2 },
          justifyContent: 'center',
          alignItems: 'flex-start',
          border: 4,
          borderColor: 'rgba(254, 231, 163, 1)',
          borderRadius: { xs: 0, sm: 2 },
          py: 2,
          mt: 2,
          mx: { xs: -1, sm: 0 },
          position: 'relative',
          backgroundColor: '#D3D3D3',
        }}
        >
          <SparkleButton
            variant="contained"
            size="large"
            color="gold"
            component={Link}
            to={getLocalizedPath('/subscriptions')}
            endIcon={<LockOpenIcon />}
            sx={{
              position: 'absolute',
              top: { xs: 38, sm: 48 },
              left: '50%',
              transform: 'translate(-50%, -50%)',
              height: { xs: 64, sm: 84 },
              width: '80%',
              maxWidth: 240,
              zIndex: 20,
            }}
          >
            {t('unlock')}
          </SparkleButton>
          {boxettesNotAvailable.map((key) => (
            <BoxetteCard
              key={key}
              title={boxettes[key].title}
              image={boxettes[key].image}
              activated={boxettesActivated(boxettes[key].disabled)}
              route={boxettes[key].route}
              dispatchBoxettes={dispatchBoxettes}
              isFavorite={boxettes[key].favorite}
              free={boxettes[key].free}
            />
          ))}
        </Box>
      )}
      <OIMLConformity />
    </Box>
  );
}
