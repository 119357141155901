import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import { useSigninCheck } from 'reactfire';
import useGlobal from 'global-state/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';

export default function BoxetteCard(props) {
  const {
    image, title, route, dispatchBoxettes, isFavorite, free = false, beta = false,
  } = props;
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const [globalState, globalActions] = useGlobal();
  const navigate = useLocalizedNavigate();

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  const isUnlocked = free || hasRequiredGrants;

  const handleBoxetteClick = () => {
    if (signInCheckResult?.signedIn === true && signInCheckResult?.user.emailVerified === false) {
      globalActions.setSnackbarMessage({ message: t('signin.verify_email'), severity: 'warning' });
    } else {
      navigate(`${route}`);
    }
  };

  const starIconColor = () => {
    if (isFavorite) {
      return 'gold';
    }
    return 'gray';
  };

  const starStrokeColor = () => {
    if (isFavorite) {
      return 'gold';
    }
    return 'black';
  };

  const tooltipText = () => {
    if (isFavorite) {
      return t('remove_from_favorites');
    }
    return t('add_to_favorites');
  };

  const handleFavoriteClick = (event) => {
    if (isFavorite) {
      dispatchBoxettes({ type: 'removeFromFavorites', name: route });
    } else {
      dispatchBoxettes({ type: 'addToFavorites', name: route });
    }
    event.stopPropagation();
  };

  const handleInfoClick = (event) => {
    globalActions.setSnackbarMessage({ message: t('boxettes.limited_access_info'), severity: 'info' });
    event.stopPropagation();
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: '48%',
        maxWidth: 300,
        minWidth: 140,
        cursor: 'pointer',
        position: 'relative',
        transition: 'transform 0.2s ease-in-out',
        overflow: 'visible',
        '&:hover': {
          transform: { xs: 'scale(1)', sm: 'scale(1.02)' },
        },
      }}
      onClick={handleBoxetteClick}
    >
      {beta && (
      <Typography
        sx={{
          position: 'absolute',
          top: -5,
          right: { xs: '72%', sm: '82%' },
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'ourGreen.main',
          borderRadius: '5px',
          padding: '2px 5px',
          zIndex: 2,
          boxShadow: 1,
        }}
      >
        Beta
      </Typography>
      )}
      {!isUnlocked && (
        <Box
          sx={{
            position: 'absolute',
            bottom: { xs: 73, sm: 98 },
            left: { xs: '15%', sm: '30%' },
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(52, 52, 52, 0.85)',
            borderRadius: '4px',
            pl: 1,
            zIndex: 3,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              fontWeight: 'medium',
              mr: 0.5,
            }}
          >
            {t('boxettes.limited_access')}
          </Typography>
          <IconButton
            sx={{
              fontSize: { xs: 18, sm: 28 },
              color: 'white',
            }}
            onClick={handleInfoClick}
          >
            <InfoIcon sx={{ fontSize: 'inherit' }} />
          </IconButton>
        </Box>
      )}
      <CardMedia
        sx={{
          height: { xs: 120, sm: 200 },
          position: 'relative',
        }}
        image={image}
        title={title}
      />

      <Tooltip title={tooltipText()}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            fontSize: 20,
            p: 0.5,
            boxShadow: 3,
            zIndex: 2,
          }}
          color={starIconColor()}
          size="large"
          aria-label="favorite"
          component="label"
          onClick={handleFavoriteClick}
        >
          <StarIcon sx={{
            stroke: starStrokeColor(),
            strokeWidth: 0.5,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.3)',
            },
          }}
          />
        </IconButton>
      </Tooltip>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: { xs: 70, sm: 95 },
        px: { xs: 0.5, sm: 3 },
      }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
      </CardContent>
    </Card>
  );
}
