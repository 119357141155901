import React from 'react';
import { useTranslation } from 'react-i18next';
import AlcoConversionResult from 'components/boxettes/alco-conversion/AlcoConversionResult';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CreditLoadingButton from 'components/CreditLoadingButton';
import AlcoConversionTAVorMV from './AlcoConversionTAVorMV';

export default function AlcoConversionBoxette(props) {
  const { t } = useTranslation();
  const {
    convertABVToTemperature,
    convertABVToTemperatureFromVolumetricMass,
    convertVolumeToVolumeAtTemp,
    volumePAConversionToTemp,
    freeBoxette,
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.alco_conversion')}
      </Typography>
      <AlcoConversionTAVorMV
        freeBoxette={freeBoxette}
        actions={<BoxetteActions />}
        convertABVToTemperature={convertABVToTemperature}
        convertABVToTemperatureFromVolumetricMass={convertABVToTemperatureFromVolumetricMass}
        convertVolumeToVolumeAtTemp={convertVolumeToVolumeAtTemp}
        volumePAConversionToTemp={volumePAConversionToTemp}
        shouldSubmitOnEndEditing
        displayTemperatureConversion
      />
    </Box>
  );
}

function BoxetteActions(props) {
  const { t } = useTranslation();
  const {
    calculating,
    formikIsValid,
    formikValues,
    alcoholConversionResults,
  } = props;

  let creditsCost = 1;
  if (formikValues.alcoholUnit === 'TAV'
    && formikValues.measuredTemp === '20.0'
    && formikValues.temperatureUnit === 'celsius'
    && formikValues.toTemp === '20.0') {
    creditsCost = 0;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
      }}
    >
      <CreditLoadingButton
        calculating={calculating}
        isValid={formikIsValid}
        cost={creditsCost}
        text={t('forms.calcul')}
        inParrallelClickHandle={() => {
          sessionStorage.removeItem('current_form:volume_conv_form');
          sessionStorage.removeItem('current_form:mass_volume_form');
          sessionStorage.removeItem('current_form:volume_pa_conv_form');
        }}
      />
      <AlcoConversionResult
        isValid={formikIsValid && Object.keys(alcoholConversionResults).length > 0}
        formikValues={formikValues}
        results={{
          alcoholConversionResults,
        }}
      />
    </Box>
  );
}
