export default function toVolConvActions(t, navigate, volume, unit) {
  if (isNaN(volume)) {
    return undefined;
  }
  return [toVolConvAction(t, navigate, volume, unit)];
}

function toVolConvAction(t, navigate, volume, unit) {
  if (isNaN(volume)) {
    return undefined;
  }
  const callback = () => navigate('/vol-conv', {
    state: {
      volumeValue: volume,
      volumeUnit: unit,
    },
  });
  return { title: t('forms.toward_volume_conversion'), callback };
}
