/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useGlobal from 'global-state/store';
import { decimalsMask } from './form';
import NumberInputWithFocus from '../components/CellNumberInput';
import { NameEditCell } from '../components/NameEditCell';

export const cellStyles = {
  baseCell: {
    backgroundColor: '#73a9b978',
  },
  fixedVolumeCell: {
    backgroundColor: '#fbe9e7',
  },
  minVolumeCell: {
    backgroundColor: '#f1f8e9',
  },
  maxVolumeCell: {
    backgroundColor: '#e8f5e9',
  },
  optimizationCell: {
    backgroundColor: '#f4ad4526',
  },
};

function CustomColumnHeader(props) {
  const { colDef, infoKey } = props;
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <span>
        {colDef.headerName}
      </span>
      {infoKey && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            globalActions.setSnackbarMessage({
              message: t(infoKey),
              severity: 'info',
            });
          }}
          sx={{
            p: 0.5,
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
          }}
        >
          <InfoOutlinedIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  );
}

export default function useColumns() {
  const { t } = useTranslation();

  const VOLUME_UNITS = [
    {
      value: 'L',
      label: 'L',
    },
    {
      value: 'percent',
      label: t('recipe_calculations.percent_vol'),
    },
    {
      value: 'percent_pa',
      label: t('recipe_calculations.percent_pa'),
    },
  ];

  const OPTIMIZATION_OPTIONS = [
    {
      value: 'none',
      label: t('recipe_calculations.optimization.none'),
    },
    {
      value: 'minimize',
      label: t('recipe_calculations.optimization.minimize'),
    },
    {
      value: 'maximize',
      label: t('recipe_calculations.optimization.maximize'),
    },
    {
      value: 'maximize_pa',
      label: t('recipe_calculations.optimization.maximize_pa'),
    },
  ];

  const ingredientsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      hide: true,
    },
    {
      field: 'name',
      headerName: t('recipe_calculations.ingredient_name'),
      width: 200,
      editable: true,
      headerClassName: 'baseCell',
      cellClassName: 'baseCell',
      renderEditCell: (params) => (
        <NameEditCell
          id={params.id}
          value={params.value}
          field={params.field}
          hasFocus={params.hasFocus}
        />
      ),
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
        />
      ),
    },
    {
      field: 'trueAbv',
      headerName: t('recipe_calculations.true_abv'),
      width: 140,
      type: 'number',
      editable: true,
      headerClassName: 'baseCell',
      cellClassName: 'baseCell',
      renderEditCell: (params) => (
        <NumberInputWithFocus
          inputsx={{
            height: '49px',
            '& .MuiOutlinedInput-root': {
              height: '49px',
            },
          }}
          value={params.value}
          onChange={(e) => params.api.setEditCellValue({ ...params, value: e.target.value })}
          decimalScale={decimalsMask['ingredients.trueAbv']}
          hasFocus={params.hasFocus}
        />
      ),
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
        />
      ),
    },
    {
      field: 'sugarDensity',
      headerName: t('recipe_calculations.sugar_density'),
      width: 160,
      type: 'number',
      editable: true,
      headerClassName: 'baseCell',
      cellClassName: 'baseCell',
      renderEditCell: (params) => (
        <NumberInputWithFocus
          inputsx={{
            height: '49px',
            '& .MuiOutlinedInput-root': {
              height: '49px',
            },
          }}
          value={params.value}
          onChange={(e) => params.api.setEditCellValue({ ...params, value: e.target.value })}
          decimalScale={decimalsMask['ingredients.sugarDensity']}
          hasFocus={params.hasFocus}
        />
      ),
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
        />
      ),
    },
    {
      field: 'minVolume',
      headerName: `${t('recipe_calculations.min_volume')}`,
      width: 160,
      type: 'number',
      editable: true,
      headerClassName: 'minVolumeCell',
      cellClassName: 'minVolumeCell',
      renderEditCell: (params) => (
        <NumberInputWithFocus
          inputsx={{
            height: '49px',
            '& .MuiOutlinedInput-root': {
              height: '49px',
            },
          }}
          value={params.value}
          onChange={(e) => params.api.setEditCellValue({ ...params, value: e.target.value })}
          decimalScale={decimalsMask['ingredients.minVolume']}
          hasFocus={params.hasFocus}
        />
      ),
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
        />
      ),
    },
    {
      field: 'minVolumeUnit',
      headerName: t('units.unit'),
      width: 140,
      editable: true,
      type: 'singleSelect',
      valueOptions: VOLUME_UNITS,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      headerClassName: 'minVolumeCell',
      cellClassName: 'minVolumeCell',
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
          infoKey="recipe_calculations.unit_info"
        />
      ),
    },
    {
      field: 'maxVolume',
      headerName: `${t('recipe_calculations.max_volume')}`,
      width: 160,
      type: 'number',
      editable: true,
      headerClassName: 'maxVolumeCell',
      cellClassName: 'maxVolumeCell',
      renderEditCell: (params) => (
        <NumberInputWithFocus
          inputsx={{
            height: '49px',
            '& .MuiOutlinedInput-root': {
              height: '49px',
            },
          }}
          value={params.value}
          onChange={(e) => params.api.setEditCellValue({ ...params, value: e.target.value })}
          decimalScale={decimalsMask['ingredients.maxVolume']}
          hasFocus={params.hasFocus}
        />
      ),
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
        />
      ),
    },
    {
      field: 'maxVolumeUnit',
      headerName: t('units.unit'),
      width: 140,
      editable: true,
      type: 'singleSelect',
      valueOptions: VOLUME_UNITS,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      headerClassName: 'maxVolumeCell',
      cellClassName: 'maxVolumeCell',
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
          infoKey="recipe_calculations.unit_info"
        />
      ),
    },
    {
      field: 'optimization',
      headerName: t('recipe_calculations.optimization.title'),
      width: 180,
      editable: true,
      type: 'singleSelect',
      valueOptions: OPTIMIZATION_OPTIONS,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
      headerClassName: 'optimizationCell',
      cellClassName: 'optimizationCell',
      renderHeader: (params) => (
        <CustomColumnHeader
          {...params}
          infoKey="recipe_calculations.optimization_info"
        />
      ),
    },
  ];

  return { ingredientsColumns };
}
