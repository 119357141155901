import React, { useState } from 'react';
import { useFormik } from 'formik';
import { roundDecimals } from 'components/boxettes/round';
import { useLocation, Link } from 'react-router';
import useMenu from 'menu-actions/useMenu';
import { useTranslation } from 'react-i18next';
import {
  reducerHistoryOperations,
  initHistoryOperations,
} from 'components/boxettes/operations-history-backups/OperationsHistoryLogic';
import {
  reducerBackupsOperations,
  initBackupsOperations,
} from 'components/boxettes/operations-history-backups/OperationBackupsLogic';
import OperationsHistoryDialog from 'components/boxettes/operations-history-backups/OperationsHistoryDialog';
import OperationBackupsDialog from 'components/boxettes/operations-history-backups/OperationBackupsDialog';
import BackupNameDialog from 'components/boxettes/operations-history-backups/BackupNameDialog';
import { addValuesAndResultsToDeeplink } from 'components/deeplinks/deeplinkUtils';
import useGlobal from 'global-state/store';
import {
  Box, Typography,
} from '@mui/material';
import { getLocalizedPath } from 'routing/navigation';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SparkleButton from 'components/common/SparkleButton';

export const BoxetteFormContext = React.createContext();

export function BoxetteForm(props) {
  const {
    decimalsMask,
    boxetteCall,
    defaultFormValues,
    savedUnits = [],
    formNameInStorage,
    validationSchema,
    applyHistoryForResults = false,
    form,
    freeBoxette,
  } = props;

  const location = useLocation();
  const [results, setResults] = useState(initialResultsInSession());
  const [calculating, setcalculating] = useState(false);
  const { dispatchMenuActions } = useMenu();
  const { t } = useTranslation();
  const [historyOpen, sethistoryOpen] = useState(false);
  const [backupsOpen, setbackupsOpen] = useState(false);
  const [backupNameOpen, setbackupNameOpen] = useState(false);
  const [historyOperations, dispatchHistoryOperations] = React.useReducer(
    reducerHistoryOperations,
    initHistoryOperations(formNameInStorage),
  );
  const [backupsOperations, dispatchBackupsOperations] = React.useReducer(
    reducerBackupsOperations,
    initBackupsOperations(formNameInStorage),
  );
  const [globalState, globalActions] = useGlobal();

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  const isUnlocked = freeBoxette || hasRequiredGrants;

  React.useEffect(() => {
    const openHistoryAction = {
      name: t('history.menu_title'),
      id: 'form_history',
      callback: () => sethistoryOpen(true),
    };
    const openBackupsAction = {
      name: t('history.menu_backups_title'),
      id: 'form_saves',
      callback: () => setbackupsOpen(true),
    };
    const saveInBackupsAction = {
      name: t('save'),
      id: 'form_save',
      callback: () => setbackupNameOpen(true),
    };
    dispatchMenuActions({
      type: 'add',
      actions: [
        saveInBackupsAction,
        { name: 'divider_bf' },
        openBackupsAction,
        openHistoryAction],
    });
  }, [dispatchMenuActions, t]);

  async function submit(values) {
    try {
      setResults({});
      setcalculating(true);
      const newResults = await boxetteCall(values);
      saveValuesAndResultsInSession({ values, results: newResults });
      dispatchHistoryOperations(
        { type: 'add', payload: { operation: { values, results: newResults }, formNameInStorage } },
      );
      addValuesAndResultsToDeeplink({ values, results: newResults, name: formNameInStorage }, globalActions);
      setResults(newResults);

      const newSavedUnits = savedUnits.reduce((collected, name) => {
        if (Object.prototype.hasOwnProperty.call(values, name)) {
          return { ...collected, [name]: values[name] };
        }
        return collected;
      }, {});

      localStorage.setItem(`units_form:${formNameInStorage}`, JSON.stringify(newSavedUnits));
      return newResults;
    } catch (e) {
      console.error(e);
      return undefined;
      // error already reported before normally but
      // still could display something here
    } finally {
      setcalculating(false);
    }
  }

  function applyNavigationValuesToDefaultValues(values) {
    const withNavFormValues = {};
    for (const [key, value] of Object.entries(values)) {
      const navigationValue = location.state?.[key];
      if (navigationValue) {
        withNavFormValues[key] = navigationValue;
      } else {
        withNavFormValues[key] = value;
      }
    }
    return withNavFormValues;
  }

  function applyLocalStoredUnitsToDefaultValues(values) {
    const savedUnitsInStorage = JSON.parse(localStorage.getItem(`units_form:${formNameInStorage}`)) || {};

    const formValuesWithSavedUnits = Object.entries(values)
      .reduce((result, [key, value]) => ({
        ...result,
        [key]: savedUnitsInStorage[key] || value,
      }), {});

    return formValuesWithSavedUnits;
  }

  function initialValuesAndUnits() {
    const initialValuesFromSession = sessionStorage.getItem(`current_form:${formNameInStorage}`);
    if (initialValuesFromSession) {
      return JSON.parse(initialValuesFromSession).values;
    }
    const valuesWithSavedUnits = applyLocalStoredUnitsToDefaultValues(defaultFormValues);
    return applyNavigationValuesToDefaultValues(valuesWithSavedUnits);
  }

  function initialResultsInSession() {
    const initialResultsFromSession = sessionStorage.getItem(`current_form:${formNameInStorage}`);
    if (initialResultsFromSession) {
      return JSON.parse(initialResultsFromSession).results;
    }
    return {};
  }

  function saveValuesAndResultsInSession(valuesAndResults) {
    sessionStorage.setItem(`current_form:${formNameInStorage}`, JSON.stringify(valuesAndResults));
  }

  const applyValuesAndResultsFromHistory = async (valuesAndResults) => {
    for await (const [key, value] of Object.entries(valuesAndResults.values)) {
      formik.setFieldValue(key, value);
    }
    if (applyHistoryForResults) {
      setResults(valuesAndResults.results);
    } else {
      setResults({});
    }
    saveValuesAndResultsInSession({ values: valuesAndResults.values, results: valuesAndResults.results });
  };

  const formik = useFormik({
    initialValues: initialValuesAndUnits(),
    validationSchema: validationSchema(),
    onSubmit(values) {
      return submit(values);
    },
  });

  const handleBlur = React.useCallback(async (event, unit) => {
    if (event.target.value === '') {
      return '';
    }

    const display = roundDecimals(event.target.value.replace(/,/, '.'), decimalsMask[unit]);
    if (formik.values[event.target.name] !== display) {
      await formik.setFieldValue(event.target.name, display);
    }
    return formik.handleBlur(event);
  }, [decimalsMask, formik]);

  const handleChange = React.useCallback(async (event) => {
    if (results !== {}
      && formik.values[event.target.name] !== event.target.value) {
      setResults({});
    }
    formik.handleChange(event);
  }, [formik, results]);

  const resultsAvailable = React.useCallback(() => (
    results !== undefined && Object.keys(results).length > 0), [results]);

  const backupSave = React.useCallback(
    () => (
      setbackupNameOpen(true)),
    [setbackupNameOpen],
  );

  const memoizedValues = React.useMemo(() => ({
    formik, handleChange, handleBlur, calculating, results, setResults, resultsAvailable, backupSave,
  }), [formik, handleChange, handleBlur, calculating, results, setResults, resultsAvailable, backupSave]);

  return (
    <BoxetteFormContext.Provider
      value={memoizedValues}
    >
      {!isUnlocked && (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        p: 3,
        my: 3,
        gap: 2,
      }}
      >
        <Typography
          variant="body1"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            mr: 1,
          }}
        >
          {t('boxettes.limited_access')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'white',
            fontWeight: 'medium',
            mr: 1,
          }}
        >
          {t('boxettes.limited_access_info')}
        </Typography>
        <SparkleButton
          size="large"
          component={Link}
          to={getLocalizedPath('/subscriptions')}
          endIcon={<LockOpenIcon />}
          sx={{
            height: 48,
            width: '80%',
            maxWidth: 200,
          }}
        >
          {t('unlock')}
        </SparkleButton>
      </Box>
      )}
      {form}
      <OperationsHistoryDialog
        open={historyOpen}
        setOpen={sethistoryOpen}
        operations={historyOperations}
        applyValuesAndResultsFromHistory={applyValuesAndResultsFromHistory}
        dispatchHistoryOperations={dispatchHistoryOperations}
        formNameInStorage={formNameInStorage}
      />
      <OperationBackupsDialog
        open={backupsOpen}
        setOpen={setbackupsOpen}
        operations={backupsOperations}
        applyValuesAndResultsFromHistory={applyValuesAndResultsFromHistory}
        dispatchBackupsOperations={dispatchBackupsOperations}
        formNameInStorage={formNameInStorage}
      />
      <BackupNameDialog
        operation={{ values: formik.values, results }}
        formNameInStorage={formNameInStorage}
        dispatchBackupsOperations={dispatchBackupsOperations}
        open={backupNameOpen}
        setOpen={setbackupNameOpen}
      />
    </BoxetteFormContext.Provider>
  );
}
