import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

export const STORAGE_KEY = 'recipe-ingredients-true-abv-options';

export function NameEditCell(props) {
  const [inputRef, setInputRef] = useState(null);

  const {
    id, value, field, hasFocus,
  } = props;

  const apiRef = useGridApiContext();

  const { t } = useTranslation();

  const initialOptions = [
    {
      name: t('water'),
      trueAbv: 0,
      sugarDensity: 0,
    },
  ];

  // Initialize state with stored options
  const [options] = useState(() => {
    const savedOptions = localStorage.getItem(STORAGE_KEY);
    if (savedOptions) {
      const parsedOptions = JSON.parse(savedOptions);
      return [...initialOptions, ...parsedOptions]
        .reduce((acc, current) => {
          // Keep only the last occurrence of each name
          const index = acc.findIndex((item) => item.name === current.name);
          if (index >= 0) {
            acc[index] = current;
          } else {
            acc.push(current);
          }
          return acc;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return initialOptions;
  });

  useEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleChange = async (event, newValue) => {
    // If newValue is an object, it means an option was selected from the dropdown
    if (newValue && typeof newValue === 'object') {
      const updatedRow = {
        ...apiRef.current.getRow(id),
        name: newValue.name,
        trueAbv: Number(newValue.trueAbv),
        sugarDensity: Number(newValue.sugarDensity),
      };

      // Update the row and wait for the processRowUpdate to handle formik
      await apiRef.current.updateRows([updatedRow]);
    } else {
      await apiRef.current.setEditCellValue({ id, field, value: newValue || '' });
    }
  };

  return (
    <Autocomplete
      fullWidth
      freeSolo
      value={value}
      inputValue={value}
      onChange={handleChange}
      onInputChange={handleChange}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.name;
      }}
      isOptionEqualToValue={(option, val) => {
        if (typeof val === 'string') return option.name === val;
        return option.name === val.name;
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        //   inputProps={params.inputProps}
        //   InputProps={params.InputProps}
        //   placeholder={params.placeholder}
          variant="outlined"
          size="small"
          inputRef={(ref) => setInputRef(ref)}
          sx={{
            width: '100%',
            margin: '0px !important',
            height: '49px',
            '& .MuiOutlinedInput-root': {
              height: '49px',
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          }}
        />
      )}
      componentsProps={{
        popper: {
          sx: {
            zIndex: 9999,
          },
        },
      }}
      sx={{
        padding: '0px !important ',
        width: '100%',
        '& .MuiAutocomplete-popper': {
          zIndex: 9999,
        },
      }}
    />
  );
}
