import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ResultCard from 'components/boxettes/ResultCard';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import { Typography, Button } from '@mui/material';
import Arrow from '@mui/icons-material/ArrowForward';
import useGlobal from 'global-state/store';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';

export default function AlcoConversionResult(props) {
  const { results, isValid, formikValues } = props;
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const [globalState] = useGlobal();

  function canNavigate() {
    return resultsAvailable() && isValid;
  }

  function resultsAvailable() {
    return (results.alcoholConversionResults !== undefined && Object.keys(results.alcoholConversionResults).length > 0);
  }

  const { ABVAt20c, MeasuredABVAtTemp } = results.alcoholConversionResults;
  const tempValue = (resultsAvailable()
    ? ABVAt20c.temperature.value : globalState.userUnits.temperatureValue);
  const tempUnit = (resultsAvailable()
    ? ABVAt20c.temperature.unit : globalState.userUnits.temperatureUnit);

  return (
    <>
      <ResultCard
        title={t('forms.converted_tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: tempValue,
          referenceTempUnit: dynamicUnitName(
            tempUnit,
          ),
        })}
        result={resultsAvailable() ? `${ABVAt20c.value} ${dynamicUnitName(ABVAt20c.unit)}` : '-'}
      />
      {formikValues.toTemp && formikValues.toTemp !== '20.0' && (
      <ResultCard
        title={t('forms.converted_tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: formikValues.toTemp,
          referenceTempUnit: dynamicUnitName(
            formikValues.temperatureUnit,
          ),
        })}
        result={resultsAvailable()
          ? `${MeasuredABVAtTemp.value} ${dynamicUnitName(MeasuredABVAtTemp.unit)}`
          : '-'}
      />
      )}
      <Button
        sx={{ marginTop: 1, width: '100%' }}
        color="secondary"
        variant="contained"
        disabled={!canNavigate()}
        onClick={() => {
          navigate('../vol-conv', {
            state: {
              alcoholConversionResults: results.alcoholConversionResults,
              temperatureUnit: formikValues.temperatureUnit,
              fromVolTemp: formikValues.measuredTemp,
            },
          });
        }}
      >
        <Typography component="span">
          {t('forms.toward_volume_conversion')}
        </Typography>
        <Arrow style={{ color: '#ffffff' }} />
      </Button>
      <Button
        stysxle={{ marginTop: 1, width: '100%' }}
        color="secondary"
        variant="contained"
        disabled={!canNavigate()}
        onClick={() => {
          navigate('../vol-pa-conv', {
            state: {
              alcoholConversionResults: results.alcoholConversionResults,
              temperatureUnit: formikValues.temperatureUnit,
              temperatureValue: formikValues.measuredTemp,
            },
          });
        }}
      >
        <Typography component="span">
          {t('forms.toward_pa_volume_conversion')}
        </Typography>
        <Arrow style={{ color: '#ffffff' }} />
      </Button>
      <Button
        sx={{ marginTop: 0, width: '100%' }}
        color="secondary"
        variant="contained"
        disabled={!canNavigate()}
        onClick={() => {
          navigate('../mass-vol-conv', {
            state: {
              alcoholConversionResults: results.alcoholConversionResults,
              temperatureUnit: formikValues.temperatureUnit,
              measuredTav: formikValues.measuredTav,
              alcoholUnit: formikValues.alcoholUnit,
              measuredTavTemp: formikValues.measuredTemp,
              toVolTemp: formikValues.measuredTemp,
            },
          });
        }}
      >
        <Typography component="span">
          {t('forms.toward_mass_volume_conversion')}
        </Typography>
        <Arrow style={{ color: '#ffffff' }} />
      </Button>
    </>
  );
}
