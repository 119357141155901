/* eslint-disable import/prefer-default-export */
// hooks/useLocalizedNavigate.js
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { getLocalizedPath } from '../routing/navigation';

export function useLocalizedNavigate() {
  const navigate = useNavigate();

  return useCallback((to, options) => {
    // console.log('to', to);
    // Handle number arguments (e.g., navigate(-1))
    if (typeof to === 'number') {
      return navigate(to);
    }

    // Handle string paths
    if (typeof to === 'string') {
      const path = getLocalizedPath(to);
      // console.log('path', path);
      return navigate(path, options);
    }

    // Handle location objects
    if (to && typeof to === 'object') {
      if (to.pathname) {
        return navigate({
          ...to,
          pathname: getLocalizedPath(to.pathname),
        }, options);
      }
      return navigate(to, options);
    }

    // Handle undefined/null (navigate to current location)
    return navigate(to, options);
  }, [navigate]);
}

// Usage in components:
// const localizedNavigate = useLocalizedNavigate();
// localizedNavigate('/subscriptions');
// localizedNavigate(-1);
// localizedNavigate({ pathname: '/account', search: '?tab=profile' });
