import React from 'react';
import { Button, keyframes } from '@mui/material';

/**
 * SparkleButton - A button with a gold CD-like appearance and subtle sparkling effect
 *
 * This component extends the Material-UI Button with a gold CD-like styling
 * that resembles a premium gold surface with occasional sparkles.
 */
function SparkleButton({
  children,
  variant = 'contained',
  size = 'large',
  color = 'gold',
  component,
  to,
  onClick,
  endIcon,
  startIcon,
  sx = {},
  ...otherProps
}) {
  // Define keyframes for the sparkle dots with quicker fade in/out
  const sparkle1 = keyframes`
    0%, 5%, 95%, 100% { opacity: 0; }
    20%, 30% { opacity: 1; }
  `;

  const sparkle2 = keyframes`
    0%, 8%, 92%, 100% { opacity: 0; }
    25%, 35% { opacity: 0.8; }
  `;

  const sparkle3 = keyframes`
    0%, 10%, 90%, 100% { opacity: 0; }
    30%, 40% { opacity: 0.6; }
  `;

  const sparkle4 = keyframes`
    0%, 3%, 97%, 100% { opacity: 0; }
    15%, 25% { opacity: 0.9; }
  `;

  const sparkle5 = keyframes`
    0%, 12%, 88%, 100% { opacity: 0; }
    35%, 45% { opacity: 0.7; }
  `;

  // Define the gold CD-like gradient
  const defaultSx = {
    color: '#5D4037', // Rich dark brown that complements gold
    fontWeight: 'bold',
    textShadow: '0 1px 1px rgba(255, 255, 255, 0.5)',
    background: `
      conic-gradient(from -218deg at 80% 65%,
        rgba(254, 231, 163, 1) 0%, /* light gold */
        rgba(254, 231, 163, 1) 5%, /* light gold */
        rgba(254, 193, 81, 1) 10%, /* medium gold */
        rgba(254, 193, 81, 1) 15%, /* medium gold */
        rgb(191, 150, 54) 20%, /* dark gold */
        rgb(207, 165, 68) 25%, /* dark gold */
        rgba(254, 193, 81, 1) 30%, /* medium gold */
        rgba(254, 231, 163, 1) 35%, /* light gold */
        rgba(240, 240, 240, 1), /* white lights */
        rgba(255, 215, 0, 1) 40%, /* gold */
        rgba(255, 215, 0, 1) 45%, /* gold */
        rgba(255, 215, 0, 1) 50%, /* gold */
        rgba(255, 215, 0, 1) 55%, /* gold */
        rgba(254, 231, 163, 1) 60%, /* light gold */
        rgba(254, 193, 81, 1) 65%, /* medium gold */
        rgb(215, 168, 56) 70%, /* dark gold */
        rgb(208, 162, 55) 75%, /* dark gold */
        rgba(254, 193, 81, 1) 80%, /* medium gold */
        rgba(254, 193, 81, 1) 85%, /* medium gold */
        rgba(254, 231, 163, 1) 90%, /* light gold */
        rgba(254, 231, 163, 1) 95%, /* light gold */
        rgba(254, 231, 163, 1) 100% /* light gold */
      )
    `,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    border: 0,
    borderRadius: 2,
    boxShadow: '0 3px 5px 2px rgba(191, 146, 45, 0.3)',
    padding: '0 30px',
    fontSize: '1rem',
    letterSpacing: '0.5px',
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      position: 'relative',
      zIndex: 5,
    },
    '&:hover': {
      filter: 'saturate(120%) brightness(1.05)',
      boxShadow: '0 5px 15px 2px rgba(191, 146, 45, 0.5)',
      color: '#4E342E', // Slightly darker brown on hover
    },
    '&:active': {
      filter: 'saturate(130%) brightness(0.95)',
      boxShadow: '0 2px 8px 1px rgba(191, 146, 45, 0.4)',
    },
    // Sparkle dots with more random positions - Group 1
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '2px',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
    },
    '&::before': {
      top: '15%',
      left: '32%',
      animation: `${sparkle1} 4.5s infinite 0.7s`,
    },
    '&::after': {
      bottom: '22%',
      right: '18%',
      animation: `${sparkle2} 5.2s infinite 3.3s`,
    },
    // Group 2
    '& > .text-wrapper::before, & > .text-wrapper::after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '2px',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      pointerEvents: 'none',
      zIndex: 1,
    },
    '& > .text-wrapper::before': {
      top: '68%',
      left: '78%',
      animation: `${sparkle3} 4.8s infinite 6.5s`,
    },
    '& > .text-wrapper::after': {
      top: '35%',
      right: '42%',
      animation: `${sparkle4} 5.5s infinite 9.8s`,
    },
    // Group 3 - Additional sparkles
    '& > .sparkle-1': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '2px',
      top: '55%',
      left: '12%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle5} 4.2s infinite 2.2s`,
    },
    '& > .sparkle-2': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '2px',
      top: '28%',
      right: '25%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle1} 5.0s infinite 8.1s`,
    },
    // Group 4 - Even more sparkles
    '& > .sparkle-3': {
      content: '""',
      position: 'absolute',
      width: '1.5px',
      height: '1.5px',
      top: '42%',
      left: '22%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle2} 4.7s infinite 5.9s`,
    },
    '& > .sparkle-4': {
      content: '""',
      position: 'absolute',
      width: '1.5px',
      height: '1.5px',
      top: '75%',
      right: '15%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle3} 5.1s infinite 1.3s`,
    },
    // Group 5 - Even more sparkles
    '& > .sparkle-5': {
      content: '""',
      position: 'absolute',
      width: '1.5px',
      height: '1.5px',
      top: '18%',
      left: '65%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle4} 4.4s infinite 7.5s`,
    },
    '& > .sparkle-6': {
      content: '""',
      position: 'absolute',
      width: '1.5px',
      height: '1.5px',
      top: '62%',
      right: '38%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 3px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle5} 4.9s infinite 4.9s`,
    },
    // Group 6 - Final sparkles
    '& > .sparkle-7': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '1px',
      top: '85%',
      left: '45%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 2px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle1} 5.3s infinite 10.4s`,
    },
    '& > .sparkle-8': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '1px',
      top: '10%',
      right: '55%',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0 0 2px 1px rgba(255, 255, 255, 0.9)',
      opacity: 0,
      zIndex: 1,
      animation: `${sparkle2} 4.6s infinite 11.7s`,
    },
  };

  // Merge the default styles with any custom styles passed in
  const mergedSx = { ...defaultSx, ...sx };

  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      component={component}
      to={to}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      sx={mergedSx}
      disabled={otherProps.disabled}
      href={otherProps.href}
      type={otherProps.type}
      fullWidth={otherProps.fullWidth}
    >
      <span className="text-wrapper" style={{ position: 'relative', zIndex: 5 }}>{children}</span>
      <div className="sparkle-1" aria-hidden="true" />
      <div className="sparkle-2" aria-hidden="true" />
      <div className="sparkle-3" aria-hidden="true" />
      <div className="sparkle-4" aria-hidden="true" />
      <div className="sparkle-5" aria-hidden="true" />
      <div className="sparkle-6" aria-hidden="true" />
      <div className="sparkle-7" aria-hidden="true" />
      <div className="sparkle-8" aria-hidden="true" />
    </Button>
  );
}

export default SparkleButton;
