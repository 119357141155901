import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { BoxetteFormContext } from '../../BoxetteForm';
import { formatNumber, formatPercentage } from '../utils/utils';
import SugarMassWithUnit from './SugarMassWithUnit';

// Utility function to determine if values match based on the unit
function isValueMatching(value, expectedValue, unit) {
  // Default threshold
  let threshold = 0.01;

  // Adjust threshold based on unit
  if (unit === 'L') {
    threshold = 1; // 1 liter threshold for volume
  } else if (unit === '%vol') {
    threshold = 0.1; // 0.1% threshold for ABV
  } else if (unit === 'g/L') {
    threshold = 1; // 1 g/L threshold for sugar density
  }

  return Math.abs(value - expectedValue) < threshold;
}

// Utility function to determine if values have a discrepancy
function hasDiscrepancy(value, expectedValue, unit) {
  return !isValueMatching(value, expectedValue, unit);
}

function ResultValue({
  locale, value, expectedValue, unit,
}) {
  const isMatching = isValueMatching(value, expectedValue, unit);
  return (
    <Typography
      variant="body1"
      sx={{
        borderBottom: '3px solid',
        borderColor: isMatching ? 'ourGreen.main' : 'ourOrange.main',
        display: 'inline-block',
        paddingBottom: '2px',
      }}
    >
      {`${formatNumber(locale, value)} ${unit}`}
    </Typography>
  );
}

export default function RecipeResults({ expectedParameters }) {
  const { t } = useTranslation();
  const { results, resultsAvailable, formik } = useContext(BoxetteFormContext);

  const locale = t('locale');

  if (!resultsAvailable()) {
    return null;
  }

  // Check if any of the ResultValues are not matching using the shared utility function
  const hasVolumeDiscrepancy = hasDiscrepancy(results.totalVolume, expectedParameters?.volume || 0, 'L');
  const hasTrueAbvDiscrepancy = hasDiscrepancy(results.expectedTrueAbv, expectedParameters?.trueAbv || 0, '%vol');
  const hasSugarDensityDiscrepancy = hasDiscrepancy(
    results.expectedSugarDensity,
    expectedParameters?.sugarDensity || 0,
    'g/L',
  );
  const hasConstraintDiscrepancy = hasVolumeDiscrepancy || hasTrueAbvDiscrepancy || hasSugarDensityDiscrepancy;

  return (
    <Box sx={{
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      alignItems: 'center',
    }}
    >
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        {t('recipe_calculations.results.title')}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {t('recipe_calculations.results.total_volume')}
            </Typography>
            <ResultValue
              locale={locale}
              value={results.totalVolume}
              expectedValue={expectedParameters?.volume}
              unit="L"
            />
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {t('recipe_calculations.true_abv')}
            </Typography>
            <ResultValue
              locale={locale}
              value={results.expectedTrueAbv}
              expectedValue={expectedParameters?.trueAbv}
              unit="%vol"
            />
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {t('recipe_calculations.sugar_density')}
            </Typography>
            <ResultValue
              locale={locale}
              value={results.expectedSugarDensity}
              expectedValue={expectedParameters?.sugarDensity}
              unit="g/L"
            />
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            {t('recipe_calculations.results.volume_pa')}
          </Typography>
          <Typography
            variant="body1"
          >
            {`${formatNumber(locale, (results.expectedTrueAbv ?? 0) * (results.totalVolume ?? 0) / 100)} L`}
          </Typography>
        </Box>
      </Box>

      {hasConstraintDiscrepancy && (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: 'ourOrange.light',
            p: 2,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            maxWidth: 922,
          }}
        >
          <WarningAmberIcon color="warning" />
          <Typography variant="body2">
            {t('recipe_calculations.errors.contraints_could_not_be_met_warning')}
          </Typography>
        </Paper>
      )}

      <Typography variant="subtitle1" gutterBottom>
        {t('recipe_calculations.results.ingredients')}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          overflowX: 'auto',
          maxWidth: '100vw',
        }}
      >
        <Table size="large">
          <TableHead>
            <TableRow>
              <TableCell>{t('recipe_calculations.results.ingredient_name')}</TableCell>
              <TableCell align="right">{t('recipe_calculations.results.volume')}</TableCell>
              <TableCell align="right">{t('recipe_calculations.results.volume_percentage')}</TableCell>
              <TableCell align="right">{t('recipe_calculations.results.volume_pa')}</TableCell>
              <TableCell align="right">{t('recipe_calculations.results.percent_pa')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.ingredients.map((ingredient) => {
              const formikIngredient = formik.values.ingredients.find(
                (i) => i.id === ingredient.id,
              ) || { trueAbv: 0, volume: 0 };
              const ingredientTrueAbv = Number(formikIngredient.trueAbv) || 0;

              const ingredientPureAlcoholVolume = (ingredientTrueAbv * ingredient.volume) / 100;
              const totalAPVolume = (results.expectedTrueAbv / 100) * results.totalVolume;
              const ingredientPercentPaVolume = totalAPVolume > 0
                ? (ingredientPureAlcoholVolume / totalAPVolume) * 100
                : 0;

              return (
                <TableRow
                  key={ingredient.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ maxWidth: '150px' }}>
                    {ingredient.name === 'LaboxBaseSyrup'
                      ? t('recipe_calculations.results.labox_base_syrup') : ingredient.name}
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: '150px' }}>
                    {`${formatNumber(locale, ingredient.volume)} L`}
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: '150px' }}>
                    {formatPercentage(locale, ingredient.volumePercentage)}
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: '150px' }}>
                    {`${formatNumber(locale, ingredientPureAlcoholVolume)} L`}
                  </TableCell>
                  <TableCell align="right" sx={{ maxWidth: '150px' }}>
                    {formatPercentage(locale, ingredientPercentPaVolume)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {results.syrupRecipe && (
        <Box>
          <Typography variant="body2" gutterBottom>
            {t('recipe_calculations.results.syrup_recipe_instructions')}
          </Typography>
          <Paper sx={{
            mt: 1, py: 1,
          }}
          >
            <Box sx={{
              display: 'flex', alignItems: 'center', gap: 1, px: 2, justifyContent: 'center',
            }}
            >
              <Typography variant="body1">
                {t('recipe_calculations.results.sugar_mass')}
              </Typography>
              <SugarMassWithUnit
                locale={locale}
                value={results.syrupRecipe.sugarMass.value}
                unit={results.syrupRecipe.sugarMass.unit}
              />
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ px: 2 }}>
              <Typography variant="body1">
                {t('recipe_calculations.results.water_volume')}
                {` ${formatNumber(locale, results.syrupRecipe.waterVolume.value)}`}
                {` ${results.syrupRecipe.waterVolume.unit}`}
              </Typography>
            </Box>
          </Paper>
          <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
            {t('recipe_calculations.results.syrup_recipe_note')}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
            {t('recipe_calculations.results.volumes_note')}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
            {t('recipe_calculations.results.syrop_alcohol_note')}
          </Typography>
        </Box>
      )}

    </Box>
  );
}
