import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, useRouteError, isRouteErrorResponse, useParams,
} from 'react-router';
import logoen from 'files/logo-alco-en.svg';
import logofr from 'files/logo-alco-fr.svg';
import { supportedLanguages } from '../i18n/i18next';

export default function ErrorPage() {
  const { t, i18n } = useTranslation();
  const error = useRouteError();
  const params = useParams();

  // Get language from URL params or fallback to current i18n language
  const lang = params.lang && supportedLanguages.includes(params.lang)
    ? params.lang
    : i18n.language;

  const logo = () => (lang === 'fr' ? logofr : logoen);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh',
    }}
    >
      <img
        src={logo()}
        style={{ width: 120, height: 'auto' }}
        alt="LAW logo"
      />
      <p>{t('nothing_here')}</p>
      {isRouteErrorResponse(error) && (
        <p>
          Status:
          {error.status}
        </p>
      )}
      <Button
        variant="contained"
        component={Link}
        to={`/${lang}/`}
      >
        {t('menu.home')}
      </Button>
    </Box>
  );
}
