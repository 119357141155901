import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router';
import useGlobal from 'global-state/store';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import { useFirestore, useSigninCheck } from 'reactfire';
import { MenuProvider } from 'menu-actions/MenuContext';
import { useTranslation } from 'react-i18next';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import LanguageGuard from 'routing/LanguageGuard';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LaboxWS from 'icons/LaboxWS';
import privacyFR from 'files/privacy-policy-fr.pdf';
import privacyEN from 'files/privacy-policy-en.pdf';
import createRoutes from '../routing/routes';
import '../prerender-setup';
import ResponsiveDrawer from './drawer-menu/ResponsiveDrawer';

function RootLayout() {
  return (
    <RemoteConfigStatus>
      <MenuProvider>
        <LanguageGuard>
          <ResponsiveDrawer />
        </LanguageGuard>
      </MenuProvider>
    </RemoteConfigStatus>
  );
}

export default function Home() {
  const [globalState, globalActions] = useGlobal();
  const { t, i18n } = useTranslation();
  const auth = getAuth();
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();
  const db = useFirestore();
  const { data: signInCheckResult } = useSigninCheck();
  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Alcoométrie, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  const hasRequiredGrants = () => {
    const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
    return requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  };

  // Get privacy policy file based on language
  const privacyPolicyFile = t('locale') === 'fr' ? privacyFR : privacyEN;

  const routes = createRoutes({
    defaultLanguage: i18n.language,
    hasRequiredGrants: hasRequiredGrants(),
    t,
    contactProps: {
      t,
      globalActions,
      analytics,
      sendEmail,
      signInCheckResult,
    },
    signInProps: {
      t,
      globalActions,
      auth,
      analytics,
      gtcuCookieName: 'agreed-to-general-terms-alcoholometry',
      ourSignInWithPopup,
      signInWithEmailAndPassword,
      createUserWithEmailAndPassword,
      sendEmailVerification,
      logEvent,
      signinCallback: () => globalActions.setUserStatus('initial'),
      LaboxWSIcon: LaboxWS,
    },
    privacyPolicyFile,
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
}
