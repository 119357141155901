import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';
import initializeSentry from 'components/SentrySetup';
import App from './App';
import reportWebVitals from './reportWebVitals';

initializeSentry();

const helmetContext = {};

const app = (
  <HelmetProvider context={helmetContext}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else {
  createRoot(rootElement).render(app);
}

reportWebVitals();

// Save state for react-snap
// window.snapSaveState = () => ({
//   __HELMET_CONTEXT__: helmetContext.helmet || {},
// });
