import React from 'react';
import useGlobal from 'global-state/store';
import CreditLoadingButton from 'components/CreditLoadingButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import MassUnitPicker from 'components/units/MassUnitPicker';
import TemperatureUnitPicker from 'components/units/TemperatureUnitPicker';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { roundDecimals } from 'components/boxettes/round';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import validationSchema from './ValidationSchema';
import MassVolumeResults from './MassVolumeResults';
import decimalsMask from './DecimalsMask';

export default function MassVolumeForm(props) {
  const { t } = useTranslation();
  const {
    convertMassToVolumeFromTemp,
    alcoholConversionResults,
    freeBoxette,
  } = props;
  const [globalState] = useGlobal();

  const boxetteCall = async (values) => {
    const results = await convertMassToVolumeFromTemp(
      {
        abvAt20cValue: alcoholConversionResults.ABVAt20c.value,
        massValue: roundDecimals(values.massValue.replace(/,/, '.'), decimalsMask[values.massUnit]),
        temperatureOutValue: roundDecimals(values.toVolTemp.replace(/,/, '.'), decimalsMask[values.temperatureUnit]),
        abvAt20cUnit: alcoholConversionResults.ABVAt20c.unit,
        massUnit: values.massUnit,
        temperatureOutUnit: values.temperatureUnit,
        volumeUnit: globalState.userUnits.volumeUnit,
        abvPrecision: alcoholConversionResults.ABVAt20c.precision,
        massPrecision: decimalsMask[values.massUnit],
        temperatureOutPrecision: decimalsMask[values.temperatureUnit],
      },
    );
    return results;
  };

  const defaultFormValues = {
    alcoholUnit: alcoholConversionResults?.ABVAt20c?.unit,
    massValue: '',
    toVolTemp: '',
    massUnit: globalState.userUnits.massUnit,
    temperatureUnit: globalState.userUnits.temperatureUnit,
  };

  return (
    <BoxetteForm
      freeBoxette={freeBoxette}
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      savedUnits={['massUnit']}
      defaultFormValues={defaultFormValues}
      formNameInStorage="mass_volume_form"
      validationSchema={() => validationSchema(t)}
      form={<InnerForm alcoholConversionResults={alcoholConversionResults} />}
    />
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, setResults,
  } = React.useContext(BoxetteFormContext);
  const { alcoholConversionResults } = props;
  const { t } = useTranslation();
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 3,
          maxWidth: { xs: '100%', sm: 300 },
        }}
      >
        <LabelInputSelect
          label={(
            <Typography component="span">
              {t('forms.weight_value')}
            </Typography>
                )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="massValue"
              placeholder="ex: 4.567"
              value={formik.values.massValue}
              decimalScale={decimalsMask[formik.values.massUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.massUnit);
              }}
            />
                )}
          select={(
            <MassUnitPicker
              unit={formik.values.massUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('massUnit', newUnit);
                setResults({});
              }}
            />
                )}
          error={
                  formik.touched.massValue
                  && formik.errors.massValue && (
                    <Typography style={{ fontSize: 10, color: 'red' }}>
                      {formik.errors.massValue}
                    </Typography>
                  )
                }
        />

        <LabelInputSelect
          label={(
            <Typography component="span">
              {t('forms.product_temp')}
            </Typography>
                )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="toVolTemp"
              placeholder="10.2"
              value={formik.values.toVolTemp}
              decimalScale={decimalsMask[formik.values.temperatureUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.temperatureUnit);
              }}
              allowNegative
            />
                )}
          select={(
            <TemperatureUnitPicker
              unit={formik.values.temperatureUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('temperatureUnit', newUnit);
                setResults({});
              }}
            />
                )}
          error={
                  formik.touched.toVolTemp
                  && formik.errors.toVolTemp && (
                    <Typography
                      component="span"
                      style={{ fontSize: 13, color: 'red' }}
                    >
                      {formik.errors.toVolTemp}
                    </Typography>
                  )
                }
        />

        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={1}
          text={t('forms.calcul')}
          inParrallelClickHandle={() => {
            sessionStorage.removeItem('current_form:volume_conv_form');
            sessionStorage.removeItem('current_form:volume_pa_with_dry_extract_conv_form');
          }}
        />
        <Typography
          sx={{ textAlign: 'left' }}
          component="span"
          variant="caption"
        >
          [1]
          {' '}
          {t('forms.real_tav_info_1')}
        </Typography>
        <Typography
          sx={{ textAlign: 'left' }}
          component="span"
          variant="caption"
        >
          [2]
          {' '}
          {t('forms.to_volume_conversion_info')}
          {' '}
          <MoreVertIcon fontSize="inherit" />
        </Typography>
        <Typography
          sx={{ textAlign: 'left' }}
          component="span"
          variant="caption"
        >
          [3]
          {' '}
          {t('forms.go_to_pa_with_dry_extract')}
          {' '}
          <MoreVertIcon fontSize="inherit" />
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: 670 },
        }}
      >
        <MassVolumeResults
          alcoholConversionResults={alcoholConversionResults}
          massVolumeConversionResults={results}
          formikValues={formik.values}
        />
      </Box>
    </Box>
  );
}
