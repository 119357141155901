import React from 'react';
import Typography from '@mui/material/Typography';
import {
  Box, Button, Paper, Link as MUILink,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import Point from './Point';

export default function XOCard(props) {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  return (
    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 300,
      height: { xs: '', md: 550 },
      pb: 3,
    }}
    >
      <Box sx={{
        width: '100%',
        borderRadius: 1,
        p: 3,
        background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)', // Gold gradient
        color: 'white',
        '&:hover': {
          background: 'linear-gradient(45deg, #FFD700 30%, #FF8C00 90%)', // Slightly darker on hover
        },
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Typography
          sx={{
            fontWeight: '900',
            mb: 1.5,
            color: 'white',
            fontSize: '1.8rem',
            textShadow: '1px 1px 1px grey',
          }}
          variant="h5"
        >
          XO
        </Typography>
        <Typography sx={{ color: '#2b4f5a' }}>
          {t('subscriptions.on_quotation')}
        </Typography>
        <Typography variant="caption" sx={{ color: '#2b4f5a' }}>
          {t('flexible_custom')}
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: 250,
        alignItems: 'flex-start',
        py: 3,
        px: 2,
        flexGrow: 1,
      }}
      >
        <Point text={t('subscriptions.boxettes_access')} />
        <Point
          text={t('subscriptions.inventory_plus')}
          infoButtonText={(
            <Trans
              i18nKey="subscriptions.inventory_plus_info"
              components={{
                1: <MUILink
                  component={Link}
                  to="https://labox-apps.com/inventories"
                  color="primary"
                  target="_blank"
                  sx={{ fontWeight: 'bold' }}
                />,
              }}
            />
          )}
        />
        <Point
          text={t('subscriptions.tt')}
          infoButtonText={(
            <Trans
              i18nKey="subscriptions.tt_info"
              components={{
                1: <MUILink
                  component={Link}
                  to="https://labox-apps.com/tanker-trucks"
                  color="primary"
                  target="_blank"
                  sx={{ fontWeight: 'bold' }}
                />,
              }}
            />
          )}
        />
        <Point
          text={t('subscriptions.gc')}
          infoButtonText={(
            <Trans
              i18nKey="subscriptions.gc_info"
              components={{
                1: <MUILink
                  component={Link}
                  to="https://labox-apps.com/gauged-containers"
                  color="primary"
                  target="_blank"
                  sx={{ fontWeight: 'bold' }}
                />,
              }}
            />
          )}
        />
        <Point text={t('subscriptions.orgs_system')} />
        <Point text={t('subscriptions.support')} />
      </Box>
      <Button
        sx={{ textTransform: 'none' }}
        size="large"
        variant="outlined"
        onClick={() => { navigate('/contact'); }}
      >
        {t('user_card.contact_us')}
      </Button>
    </Paper>
  );
}
