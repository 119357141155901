import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { BoxetteForm } from '../BoxetteForm';
import BoxetteFormContent from './components/BoxetteFormContent';
import { defaultFormValues, decimalsMask } from './constants/form';
import validationSchema from './utils/validation';
import { STORAGE_KEY } from './components/NameEditCell';

export default function RecipeCalculationsTrueAbvBoxette({ recipeCalculationsTrueAbv, freeBoxette }) {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  const saveIngredientsToLocalStorage = (values) => {
    const savedOptions = localStorage.getItem(STORAGE_KEY);
    const existingOptions = savedOptions ? JSON.parse(savedOptions) : [];

    // Add new ingredients to options if they don't exist
    const newOptions = values.ingredients.reduce((acc, ingredient) => {
      const exists = existingOptions.some((opt) => opt.name === ingredient.name);
      if (!exists && ingredient.name) {
        acc.push({
          name: ingredient.name,
          trueAbv: ingredient.trueAbv,
          sugarDensity: ingredient.sugarDensity,
        });
      }
      return acc;
    }, []);

    if (newOptions.length > 0) {
      // Combine existing and new options, sort by name, and keep only the most recent 19 (plus water)
      const allOptions = [...existingOptions, ...newOptions]
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((acc, current) => {
          // Keep only the last occurrence of each name
          const index = acc.findIndex((item) => item.name === current.name);
          if (index >= 0) {
            acc[index] = current;
          } else {
            acc.push(current);
          }
          return acc;
        }, [])
        .slice(0, 20);

      localStorage.setItem(STORAGE_KEY, JSON.stringify(allOptions));
    }
  };

  const boxetteCall = async (values) => {
    saveIngredientsToLocalStorage(values);

    // Validate ingredients have sufficient alcohol and sugar content
    const hasHighEnoughAbv = values.ingredients.some(
      (ingredient) => ingredient.trueAbv >= values.expectedParameters.trueAbv,
    );

    if (!hasHighEnoughAbv) {
      globalActions.setSnackbarMessage({
        message: t('recipe_calculations.errors.no_ingredient_with_high_enough_abv'),
        severity: 'warning',
      });
      return {};
    }

    // Validate there is at least one ingredient with lower alcohol content
    const hasLowerAbv = values.ingredients.some(
      (ingredient) => ingredient.trueAbv <= values.expectedParameters.trueAbv,
    );

    if (!hasLowerAbv) {
      globalActions.setSnackbarMessage({
        message: t('recipe_calculations.errors.no_ingredient_with_lower_abv'),
        severity: 'warning',
      });
      return {};
    }

    // Skip sugar validation if using crystallized sugar
    if (!values.useCrystallizedSugar) {
      const hasHighEnoughSugar = values.ingredients.some(
        (ingredient) => ingredient.sugarDensity >= values.expectedParameters.sugarDensity,
      );

      if (!hasHighEnoughSugar) {
        globalActions.setSnackbarMessage({
          message: t('recipe_calculations.errors.no_ingredient_with_high_enough_sugar'),
          severity: 'warning',
        });
        return {};
      }
    }

    const result = await recipeCalculationsTrueAbv(values);

    return result && Object.keys(result).length > 0 ? { ...result.recipe, syrupRecipe: result.syrupRecipe } : {};
  };

  return (
    <BoxetteForm
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="recipe_calculations_true_abv"
      validationSchema={() => validationSchema(t)}
      form={<BoxetteFormContent />}
      freeBoxette={freeBoxette}
    />
  );
}
