import { getLocalizedPath } from '../routing/navigation';

const seoConfig = {
  default: {
    titleKey: 'seo.default_title',
    descriptionKey: 'seo.default_description',
    pathname: '/',
  },
  experts: {
    titleKey: 'seo.experts.title',
    descriptionKey: 'seo.experts.description',
    pathname: '/experts',
  },
  subscriptions: {
    titleKey: 'seo.subscriptions.title',
    descriptionKey: 'seo.subscriptions.description',
    pathname: '/subscriptions',
  },
  contact: {
    titleKey: 'seo.contact.title',
    descriptionKey: 'seo.contact.description',
    pathname: '/contact',
  },
  signin: {
    titleKey: 'seo.signin.title',
    descriptionKey: 'seo.signin.description',
    pathname: '/signin',
  },
  account: {
    titleKey: 'seo.account.title',
    descriptionKey: 'seo.account.description',
    pathname: '/account',
  },
  tips: {
    titleKey: 'seo.tips.title',
    descriptionKey: 'seo.tips.description',
    pathname: '/tips',
  },
  automated: {
    titleKey: 'seo.automated.title',
    descriptionKey: 'seo.automated.description',
    pathname: '/automated',
  },
  'about-us': {
    titleKey: 'seo.about_us.title',
    descriptionKey: 'seo.about_us.description',
    pathname: '/about-us',
  },
  // Boxettes routes
  'boxettes/alco-conv': {
    titleKey: 'seo.boxettes.alco_conv.title',
    descriptionKey: 'seo.boxettes.alco_conv.description',
    pathname: '/alco-conv',
  },
  'boxettes/vol-conv': {
    titleKey: 'seo.boxettes.vol_conv.title',
    descriptionKey: 'seo.boxettes.vol_conv.description',
    pathname: '/vol-conv',
  },
  'boxettes/ullaging': {
    titleKey: 'seo.boxettes.ullaging.title',
    descriptionKey: 'seo.boxettes.ullaging.description',
    pathname: '/ullaging',
  },
  'boxettes/mass-vol-conv': {
    titleKey: 'seo.boxettes.mass_vol_conv.title',
    descriptionKey: 'seo.boxettes.mass_vol_conv.description',
    pathname: '/mass-vol-conv',
  },
  'boxettes/vol-pa-conv': {
    titleKey: 'seo.boxettes.vol_pa_conv.title',
    descriptionKey: 'seo.boxettes.vol_pa_conv.description',
    pathname: '/vol-pa-conv',
  },
  'boxettes/vol-alco-conv': {
    titleKey: 'seo.boxettes.vol_alco_conv.title',
    descriptionKey: 'seo.boxettes.vol_alco_conv.description',
    pathname: '/vol-alco-conv',
  },
  'boxettes/reduction-improvement': {
    titleKey: 'seo.boxettes.reduction_improvement.title',
    descriptionKey: 'seo.boxettes.reduction_improvement.description',
    pathname: '/reduction-improvement',
  },
  'boxettes/ebulliometer': {
    titleKey: 'seo.boxettes.ebulliometer.title',
    descriptionKey: 'seo.boxettes.ebulliometer.description',
    pathname: '/ebulliometer',
  },
  'boxettes/unit-conversions': {
    titleKey: 'seo.boxettes.unit_conversions.title',
    descriptionKey: 'seo.boxettes.unit_conversions.description',
    pathname: '/unit-conversions',
  },
  'boxettes/mass-vol-alco-conv': {
    titleKey: 'seo.boxettes.mass_vol_alco_conv.title',
    descriptionKey: 'seo.boxettes.mass_vol_alco_conv.description',
    pathname: '/mass-vol-alco-conv',
  },
  'boxettes/energetic-value': {
    titleKey: 'seo.boxettes.energetic_value.title',
    descriptionKey: 'seo.boxettes.energetic_value.description',
    pathname: '/energetic-value',
  },
  'boxettes/mutage': {
    titleKey: 'seo.boxettes.mutage.title',
    descriptionKey: 'seo.boxettes.mutage.description',
    pathname: '/mutage',
  },
  'boxettes/pa-conv-with-dry-extract': {
    titleKey: 'seo.boxettes.pa_conv_with_dry_extract.title',
    descriptionKey: 'seo.boxettes.pa_conv_with_dry_extract.description',
    pathname: '/pa-conv-with-dry-extract',
  },
  'boxettes/alco-to-us-conv': {
    titleKey: 'seo.boxettes.alco_to_us_conv.title',
    descriptionKey: 'seo.boxettes.alco_to_us_conv.description',
    pathname: '/alco-to-us-conv',
  },
  'boxettes/ingredient-adjustments': {
    titleKey: 'seo.boxettes.ingredient_adjustments.title',
    descriptionKey: 'seo.boxettes.ingredient_adjustments.description',
    pathname: '/ingredient-adjustments',
  },
  'boxettes/dryExtractObscuration': {
    titleKey: 'seo.boxettes.dry_extract_obscuration.title',
    descriptionKey: 'seo.boxettes.dry_extract_obscuration.description',
    pathname: '/dryExtractObscuration',
  },
  'boxettes/flash-point': {
    titleKey: 'seo.boxettes.flash_point.title',
    descriptionKey: 'seo.boxettes.flash_point.description',
    pathname: '/flash-point',
  },
  'boxettes/active-so2': {
    titleKey: 'seo.boxettes.active_so2.title',
    descriptionKey: 'seo.boxettes.active_so2.description',
    pathname: '/active-so2',
  },
  'boxettes/active-so2/rectify': {
    titleKey: 'seo.boxettes.active_so2_rectify.title',
    descriptionKey: 'seo.boxettes.active_so2_rectify.description',
    pathname: '/active-so2/rectify',
  },
  'boxettes/free-so2': {
    titleKey: 'seo.boxettes.free_so2.title',
    descriptionKey: 'seo.boxettes.free_so2.description',
    pathname: '/free-so2',
  },
};

// Helper function to get SEO config with translated pathname
export function getLocalizedSeoConfig(path) {
  // Find the matching config
  const configKey = Object.keys(seoConfig).find((key) => {
    const configPath = seoConfig[key].pathname;
    // Remove leading/trailing slashes for comparison
    const normalizedConfigPath = configPath.replace(/^\/+|\/+$/g, '');
    const normalizedPath = path.replace(/^\/+|\/+$/g, '');
    return normalizedConfigPath === normalizedPath;
  });

  const config = seoConfig[configKey] || seoConfig.default;

  // Return config with translated pathname
  return {
    ...config,
    pathname: getLocalizedPath(config.pathname),
  };
}

export default seoConfig;
