import React, { useEffect, useState } from 'react';
import {
  Button, Typography, Link as MUILink, Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Refresh from '@mui/icons-material/Refresh';
import version from 'files/version.txt';
import { Link } from 'react-router';
import { getLocalizedPath } from 'routing/navigation';

export default function Footer({ minimal = false }) {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');

  const handleSwitchLongPollingChange = (event) => {
    localStorage.setItem('forceLongPolling', event.target.checked.toString());
    window.location.reload();
  };

  useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      }).catch((error) => {
        throw new Error(`Footer fetch version failed: ${error}`);
      });
  }, []);

  const knownDatabases = ['firebase-heartbeat-database',
    'firebase-installations-database', 'firebaseLocalStorageDb',
    'firebase_remote_config', 'firestore/[DEFAULT]/labox-ws/main'];

  const clearIndexedDB = () => {
    if (window.indexedDB.databases) {
      window.indexedDB.databases().then((databases) => {
        databases.forEach((database) => {
          window.indexedDB.deleteDatabase(database.name);
        });
      });
    } else {
    // Fallback to predefined list
      knownDatabases.forEach((dbName) => {
        window.indexedDB.deleteDatabase(dbName);
      });
    }
  };
  async function clearCacheStorage() {
    const keys = await caches.keys();
    keys.forEach(async (key) => {
      await caches.delete(key);
    });
  }

  const clearApplicationCache = () => {
    localStorage.clear();
    sessionStorage.clear();

    // Clearing cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    clearIndexedDB();
    clearCacheStorage();

    window.location.reload();
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      mt: 6,
      mb: 3,
    }}
    >

      {minimal === false && (
      <Typography component="span" variant="body2" style={{ marginTop: 10, width: '100%' }}>
        {t('welcome.contact_footer')}
        {' '}

        <MUILink
          component={Link}
          to={getLocalizedPath('/contact')}
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@labox-apps.com
        </MUILink>
      </Typography>
      )}

      {minimal === false && (
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          mt: 1,
        }}
      >
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to={getLocalizedPath('/cgu')}
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.gtcu')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to={getLocalizedPath('/cgv')}
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.cgv')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to={getLocalizedPath('/privacy-policy')}
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.privacy_policy')}
        </Button>
      </Box>
      )}
      <Typography variant="caption">
        {t('settings.app_version', { appVersion })}
      </Typography>
      <Button
        sx={{ alignSelf: 'center' }}
        variant="outlined"
        size="small"
        onClick={() => window.location.reload()}
        endIcon={<Refresh />}
      >
        <Typography variant="caption">
          {t('reload')}
        </Typography>
      </Button>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        mt: 1,
      }}
      >
        <Typography variant="caption">
          {t('long_polling_switch')}
        </Typography>
        <Switch
          size="small"
          checked={localStorage.getItem('forceLongPolling') === 'true'}
          onChange={handleSwitchLongPollingChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Button
          variant="text"
          size="small"
          onClick={clearApplicationCache}
          endIcon={<Refresh />}
        >
          <Typography variant="caption">
            {t('clear_cache')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
