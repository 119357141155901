/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import { NumberInput } from 'components/boxettes/BoxetteInputs';
import React, { useState, useEffect } from 'react';

export default function NumberInputWithFocus({ hasFocus, ...props }) {
  const [inputRef, setInputRef] = useState(null);

  useEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  return (
    <Box sx={{ width: '100%', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}>
      <NumberInput
        {...props}
        inputRef={(ref) => setInputRef(ref)}
      />
    </Box>
  );
}
