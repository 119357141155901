import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ActiveSO2Form from './ActiveSO2Form';

export default function ActiveSO2Boxette(props) {
  const { convertFreeSO2, convertActiveSO2, freeBoxette } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    }}
    >
      <Typography variant="h4" component="span">
        {t('boxettes.active_so2')}
      </Typography>
      <ActiveSO2Form
        convertFreeSO2={convertFreeSO2}
        convertActiveSO2={convertActiveSO2}
        freeBoxette={freeBoxette}
      />

      <Typography align="left" component="i">
        {t('so2.free_so2_info', { joinArrays: '' })}
      </Typography>
    </Box>

  );
}
