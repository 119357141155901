import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import AlcoConversionTAVForm from './AlcoConversionTAVForm';
import AlcoConversionMVForm from './AlcoConversionMVForm';

export default function AlcoConversionTAVorMV(props) {
  const {
    actions,
    shouldSubmitOnEndEditing,
    convertABVToTemperature,
    convertABVToTemperatureFromVolumetricMass,
    convertVolumeToVolumeAtTemp,
    volumePAConversionToTemp,
    displayTemperatureConversion,
    freeBoxette,
  } = props;
  const [tab, setTab] = React.useState(initTab());
  const { t } = useTranslation();

  function initTab() {
    const savedTab = localStorage.getItem('last_alco_conversion_tab');
    if (!savedTab) {
      return 'tav';
    }
    return savedTab;
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('last_alco_conversion_tab', newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered>
            <Tab label={t('forms.with_tav')} value="tav" />
            <Tab label={t('forms.with_mv')} value="mv" />
          </TabList>
        </Box>
        <TabPanel value="tav">
          <AlcoConversionTAVForm
            freeBoxette={freeBoxette}
            convertABVToTemperature={convertABVToTemperature}
            convertVolumeToVolumeAtTemp={convertVolumeToVolumeAtTemp}
            volumePAConversionToTemp={volumePAConversionToTemp}
            actions={actions}
            shouldSubmitOnEndEditing={shouldSubmitOnEndEditing}
            displayTemperatureConversion={displayTemperatureConversion}
          />
        </TabPanel>
        <TabPanel value="mv">
          {' '}
          <AlcoConversionMVForm
            freeBoxette={freeBoxette}
            convertABVToTemperatureFromVolumetricMass={convertABVToTemperatureFromVolumetricMass}
            convertVolumeToVolumeAtTemp={convertVolumeToVolumeAtTemp}
            volumePAConversionToTemp={volumePAConversionToTemp}
            actions={actions}
            shouldSubmitOnEndEditing={shouldSubmitOnEndEditing}
          />

        </TabPanel>
      </TabContext>
      <Typography
        sx={{ textAlign: 'left' }}
        component="span"
        variant="caption"
      >
        {t('forms.tav_info_1')}
        <br />
        {t('forms.tav_info_2')}
        <br />
        {displayTemperatureConversion && (t('forms.tav_info_3'))}
      </Typography>

    </Box>

  );
}
