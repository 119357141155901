import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import CreditLoadingButton from 'components/CreditLoadingButton';
import ResultCard from 'components/boxettes/ResultCard';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';

export default function IngredientAdjustmentsBoxette(props) {
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { ingredientAdjustments, freeBoxette } = props;

  const replaceComma = (value) => {
    if (value !== null) {
      return value.toString().replace(/,/, '.');
    }
    return value;
  };

  function between(value, a, b) {
    const min = Math.min.apply(Math, [a, b]);
    const max = Math.max.apply(Math, [a, b]);
    return value > min && value < max;
  }

  const boxetteCall = async (values) => {
    if (!between(values.finalDensity, values.ingredientToAddDensity, values.productDensity)) {
      globalActions.setSnackbarMessage({ message: t('ingredient_adjustments.impossible'), severity: 'warning' });
      return {};
    }
    const results = await ingredientAdjustments(
      {
        ingredientConcentrationInValue: replaceComma(values.productDensity),
        ingredientConcentrationAddValue: replaceComma(values.ingredientToAddDensity),
        ingredientConcentrationOutValue: replaceComma(values.finalDensity),
        volumeInValue: replaceComma(values.startVolume),

        ingredientConcentrationInUnit: values.densityUnit,
        ingredientConcentrationAddUnit: values.densityUnit,
        ingredientConcentrationOutUnit: values.densityUnit,
        volumeInUnit: values.volumeUnit,
        ingredientVolumeToAddUnit: values.volumeUnit,
        finalVolumeUnit: values.volumeUnit,

        ingredientConcentrationInPrecision: decimalsMask[values.densityUnit],
        ingredientConcentrationAddPrecision: decimalsMask[values.densityUnit],
        ingredientConcentrationOutPrecision: decimalsMask[values.densityUnit],
        volumeInPrecision: decimalsMask[values.volumeUnit],
      },
    );
    return results;
  };

  const defaultFormValues = {
    productDensity: '',
    ingredientToAddDensity: '',
    finalDensity: '',
    startVolume: '',
    densityUnit: globalState.userUnits.densityUnit,
    volumeUnit: globalState.userUnits.volumeUnit,
  };

  return (
    <BoxetteForm
      freeBoxette={freeBoxette}
      applyHistoryForResults
      decimalsMask={decimalsMask}
      savedUnits={['densityUnit', 'volumeUnit']}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="ingredient_adjustment_form"
      validationSchema={() => validationSchema(t)}
      form={(
        <InnerForm />)}
    />

  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable, setResults, backupSave,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.ingredient_adjustments')}
      </Typography>

      <DensityLabelInputPicker
        handleChange={handleChange}
        formik={formik}
        handleBlur={handleBlur}
        label={t('ingredient_adjustments.initial_density')}
        value="productDensity"
        placeholder="ex: 2.5"
      />

      <DensityLabelInputPicker
        handleChange={handleChange}
        formik={formik}
        handleBlur={handleBlur}
        label={t('ingredient_adjustments.density_to_add')}
        value="ingredientToAddDensity"
        placeholder="ex: 34"
      />

      <DensityLabelInputPicker
        handleChange={handleChange}
        formik={formik}
        handleBlur={handleBlur}
        label={t('ingredient_adjustments.expected_density')}
        value="finalDensity"
        placeholder="ex: 3"
      />

      <LabelInputSelect
        label={
          <Typography component="span">{t('ingredient_adjustments.volume')}</Typography>
        }
        input={(
          <NumberInput
            id="startVolume"
            name="startVolume"
            value={formik.values.startVolume}
            placeholder="ex: 100"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.volumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.volumeUnit);
            }}
          />
        )}
        error={
          formik.touched.startVolume
          && formik.errors.startVolume && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.startVolume}
            </Typography>
          )
        }
        select={(
          <VolumeUnitPicker
            unit={formik.values.volumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('volumeUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />

      <Box sx={{ display: 'flex', justifyContent: 'stretch', gap: 1 }}>
        <Box sx={{ flexGrow: 5 }}>
          <CreditLoadingButton
            calculating={calculating}
            isValid={formik.isValid}
            cost={1}
            text={t('forms.calcul')}
          />
        </Box>
        <Button
          color="secondary"
          variant="contained"
          sx={{ flexGrow: 1 }}
          onClick={backupSave}
        >
          <SaveAsIcon />
        </Button>
      </Box>
      <ResultCard
        title={t('ingredient_adjustments.volume_to_add')}
        result={resultsAvailable()
          ? `${results.ingredientVolumeToAdd.value} ${dynamicUnitName(results.ingredientVolumeToAdd.unit)}`
          : '-'}
      />
      <ResultCard
        title={t('ingredient_adjustments.volume_total')}
        result={resultsAvailable()
          ? `${results.finalVolume.value} ${dynamicUnitName(results.finalVolume.unit)}`
          : '-'}
      />

    </form>
  );
}

function DensityLabelInputPicker(props) {
  const {
    handleChange, formik, label, value, placeholder, handleBlur,
  } = props;
  return (
    <LabelInputSelect
      label={
        <Typography component="span">{label}</Typography>
        }
      input={(
        <NumberInput
          onChange={handleChange}
          name={value}
          placeholder={placeholder}
          value={formik.values[value]}
          decimalScale={decimalsMask[formik.values.densityUnit]}
          onBlur={(e) => {
            handleBlur(e, formik.values.densityUnit);
          }}
        />
        )}
      error={formik.touched[value] && formik.errors[value] && (
      <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
        {formik.errors[value]}
      </Typography>
      )}
      select={(
        <DensityUnitPicker
          unit={formik.values.densityUnit}
          setUnit={async (newUnit) => {
            await formik.setFieldValue('densityUnit', newUnit);
          }}
        />
        )}
    />
  );
}
