import { nanoid } from 'nanoid';

export const defaultFormValues = {
  useCrystallizedSugar: false,
  expectedParameters: {
    trueAbv: '',
    sugarDensity: '',
    volume: '',
  },
  ingredients: [{
    id: nanoid(),
    name: '',
    trueAbv: '',
    sugarDensity: '',
    minVolume: '',
    maxVolume: '',
    minVolumeUnit: 'L',
    maxVolumeUnit: 'L',
    optimization: 'none',
  }],
};

export const decimalsMask = {
  'expectedParameters.trueAbv': 2,
  'expectedParameters.sugarDensity': 2,
  'expectedParameters.volume': 2,
  'ingredients.trueAbv': 2,
  'ingredients.sugarDensity': 2,
  'ingredients.minVolume': 2,
  'ingredients.maxVolume': 2,
};
