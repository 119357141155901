/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

const ingredientSchema = (t) => Yup.object().shape({
  id: Yup.mixed().required(),
  name: Yup.string().required(t('validations.required')),
  type: Yup.string(),
  trueAbv: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(t('validations.required'))
    .min(0, t('validations.positive_value'))
    .max(100, t('validations.tav_validation', { tavMin: 0, tavMax: 100, alcoholUnit: '%vol' })),
  sugarDensity: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required(t('validations.required'))
    .min(0, t('validations.positive_value')),
  minVolume: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .min(0, t('validations.positive_value')),
  maxVolume: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .min(0, t('validations.positive_value')),
  minVolumeUnit: Yup.string(),
  maxVolumeUnit: Yup.string(),
}).test('min-max-volume', t('validations.min_max_volume'), (value) => {
  if (value.minVolume && value.maxVolume) {
    return value.minVolume <= value.maxVolume;
  }
  return true;
});

export default function validationSchema(t) {
  return Yup.object().shape({
    expectedParameters: Yup.object().shape({
      trueAbv: Yup.number()
        .required(t('validations.required'))
        .min(0, t('validations.positive_value'))
        .max(100, t('validations.tav_validation', { tavMin: 0, tavMax: 100, alcoholUnit: '%vol' })),
      sugarDensity: Yup.number()
        .required(t('validations.required'))
        .min(0, t('validations.positive_value')),
      volume: Yup.number()
        .required(t('validations.required'))
        .min(0, t('validations.positive_value')),
    }),
    ingredients: Yup.array()
      .of(ingredientSchema(t))
      .min(1, t('validations.required')),
  });
}
