export const setCookiesAccepted = (store, boolean) => {
  store.setState({ cookiesAccepted: boolean });
};

export const setUserUnits = (store, units) => {
  store.setState({ userUnits: units });
  localStorage.setItem('user_units_config_v2', JSON.stringify({ ...units }));
};

export const setSnackbarMessage = (store, options = { message: '', severity: 'info', duration: 15000 }) => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity,
      displayDuration: options.duration,
      linkPath: options.linkPath,
      linkMessageKey: options.linkMessageKey,
      linkMessageProps: options.linkMessageProps,
    },
  });
};

export const closeSnackbar = (store) => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserRoles = (store, anArray) => {
  store.setState({ roles: anArray });
};

export const setUserIssuers = (store, anArray) => {
  store.setState({ issuers: anArray });
};

export const setActiveOrganization = (store, organization) => {
  store.setState({ activeOrganization: organization });
};

export const setUserOrganizations = (store, organizations) => {
  store.setState({ userOrganizations: organizations });
};

export const setUserStatus = (store, status) => {
  store.setState({ userStatus: status });
};

export const setAlcoApiConfig = (store, anObject) => {
  store.setState({ alcoApiConfig: anObject });
};

export const setCurrentDeeplink = (store, deeplink) => {
  store.setState({ currentDeeplink: deeplink });
};

export const setAccessGrants = (store, anArray) => {
  store.setState({ accessGrants: anArray });
};

export const setAccessGrantNames = (store, anArray) => {
  store.setState({ accessGrantNames: anArray });
};

export const setNetworkState = (store, state) => {
  store.setState({ networkState: state });
};
