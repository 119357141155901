import { findPathByValue } from '../routing/navigation';

export function menuPaths(location) {
  const basePaths = [
    '/',
    'signin',
    'automated',
    'about-us',
    'experts',
    'tech-info',
    'labox-news',
    'subscriptions',
    'issuers',
    'admin',
    'tips',
  ];

  // Remove language prefix and leading/trailing slashes
  const currentPath = location.pathname.replace(/^\/[a-z]{2}\//, '').replace(/^\/+|\/+$/g, '');

  // If path is empty (just language code or root), check against root path
  if (!currentPath) {
    return basePaths.includes('/');
  }

  // Find the base path for the current translated path
  const basePath = findPathByValue(currentPath);

  // Check if the base path is in our list
  return basePaths.includes(basePath) || basePaths.includes(`/${basePath}`);
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useIntervalAsync = (fn, ms, useRef, useCallback, useEffect) => {
  const timeout = useRef();
  const mountedRef = useRef(false);

  const run = useCallback(async () => {
    await fn();
    if (mountedRef.current) {
      timeout.current = window.setTimeout(run, ms);
    }
  }, [fn, ms]);

  useEffect(() => {
    mountedRef.current = true;
    run();
    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [run]);
};

export const delay = (ms) => new Promise(
  // eslint-disable-next-line no-promise-executor-return
  (resolve) => setTimeout(resolve, ms),
);

export function navigatorNetworkState() {
  return navigator.onLine ? 'online' : 'offline';
}
