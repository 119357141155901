import React from 'react';
import { Contact } from 'labox-ws-commons';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';

export default function ContactWrapper({
  t,
  globalActions,
  analytics,
  sendEmail,
  signInCheckResult,
}) {
  const navigate = useLocalizedNavigate();

  return (
    <Contact
      t={t}
      globalActions={globalActions}
      analytics={analytics}
      sendEmail={sendEmail}
      signInCheckResult={signInCheckResult}
      navigate={navigate}
    />
  );
}
