import React from 'react';
import { SignIn } from 'labox-ws-commons';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { Link, useLocation } from 'react-router';

export default function SignInWrapper({
  t,
  globalActions,
  auth,
  analytics,
  gtcuCookieName,
  ourSignInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  logEvent,
  signinCallback,
  LaboxWSIcon,
}) {
  const navigate = useLocalizedNavigate();
  const location = useLocation();

  return (
    <SignIn
      t={t}
      globalActions={globalActions}
      auth={auth}
      analytics={analytics}
      gtcuCookieName={gtcuCookieName}
      ourSignInWithPopup={ourSignInWithPopup}
      signInWithEmailAndPassword={signInWithEmailAndPassword}
      createUserWithEmailAndPassword={createUserWithEmailAndPassword}
      sendEmailVerification={sendEmailVerification}
      logEvent={logEvent}
      signinCallback={signinCallback}
      LaboxWSIcon={LaboxWSIcon}
      navigate={navigate}
      location={location}
      Link={Link}
    />
  );
}
