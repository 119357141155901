import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Box,
  Paper,
  Typography,
  IconButton,
  Fade,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Papa from 'papaparse';
import { nanoid } from 'nanoid';
import faqCsvFr from 'components/faq/faq-fr.csv';
import faqCsvEn from 'components/faq/faq-en.csv';

// Utility function to make all links open in a new tab
const makeLinksOpenInNewTab = (htmlContent) => {
  if (!htmlContent) return htmlContent;
  // Regular expression to find all <a> tags that don't already have target="_blank"
  return htmlContent.replace(
    /<a\s+(?![^>]*target="_blank")([^>]*)>/gi,
    '<a $1 target="_blank" rel="noopener noreferrer">',
  );
};

export default function BoxettesSearch() {
  const { t } = useTranslation();
  const [faqItems, setFaqItems] = useState([]);
  const [featuredFaqItems, setFeaturedFaqItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [filteredFAQs, setFilteredFAQs] = useState([]);
  const [showFAQ, setShowFAQ] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const searchRef = useRef(null);
  const popperRef = useRef(null);
  const containerRef = useRef(null);
  const isSelectingRef = useRef(false);

  // Get container width for popper
  const getContainerWidth = () => {
    if (containerRef.current) {
      return `${containerRef.current.offsetWidth}px !important`;
    }
    return '100% !important';
  };

  const getFaqCsv = () => {
    const language = t('locale');
    if (language === 'fr') {
      return faqCsvFr;
    }
    return faqCsvEn;
  };

  // Parse CSV file on component mount
  useEffect(() => {
    const fetchFAQData = async () => {
      try {
        const response = await fetch(getFaqCsv());
        const csvText = await response.text();

        Papa.parse(csvText, {
          delimiter: ';',
          complete: (results) => {
            const parsedItems = results.data
              .filter((row) => row.length >= 3 && row[0] && row[1])
              .map((row) => ({
                id: nanoid(),
                question: row[0],
                answer: makeLinksOpenInNewTab(row[1]),
                keywords: row[2] ? row[2].split(',').map((k) => k.trim()) : [],
                priority: row[3] ? parseInt(row[3], 10) : null,
              }));
            setFaqItems(parsedItems);
            // Extract and sort featured FAQ items (those with priority numbers)
            const featured = parsedItems
              .filter((item) => item.priority !== null)
              .sort((a, b) => a.priority - b.priority);
            setFeaturedFaqItems(featured);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
          },
        });
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      }
    };

    fetchFAQData();
  }, []);

  // Filter FAQs based on search query
  useEffect(() => {
    if (!searchQuery.trim() && !selectedQuestion) {
      // If no search query and no selected question, show featured items if available
      setFilteredFAQs(featuredFaqItems.length > 0 ? featuredFaqItems : []);
      return;
    }

    // If we have a selected question, show it in the filtered list
    if (selectedQuestion) {
      // We'll set the filtered FAQs directly to the selected question
      // This avoids the need to check if it exists in the current filteredFAQs array
      setFilteredFAQs([selectedQuestion]);
      return;
    }

    const normalizedQuery = searchQuery.toLowerCase().trim();

    const filtered = faqItems.filter((item) => {
      // Check if query matches question
      if (item.question.toLowerCase().includes(normalizedQuery)) {
        return true;
      }

      // Check if query matches any keyword
      return item.keywords.some((keyword) => keyword.toLowerCase().includes(normalizedQuery));
    });

    setFilteredFAQs(filtered);
  }, [searchQuery, faqItems, selectedQuestion, featuredFaqItems]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    // Only show options if there's text in the search field
    if (value.trim()) {
      setShowFAQ(true);
      setIsOptionsOpen(true);
      setShowAnswers(false);
    } else {
      // If empty, but we have featured items, still show the dropdown
      if (featuredFaqItems.length > 0) {
        setShowFAQ(true);
        setIsOptionsOpen(true);
      } else {
        setShowFAQ(false);
        setIsOptionsOpen(false);
      }
      setShowAnswers(false);
    }
  };

  const handleSearchFocus = () => {
    if (selectedQuestion) {
      // If a question is already selected, show the answers
      setShowAnswers(true);
    } else {
      // Show dropdown with either search results or featured items
      setShowFAQ(true);
      setIsOptionsOpen(true);
    }
  };

  const handleCloseFAQ = () => {
    if (isSelectingRef.current) {
      return;
    }
    setShowFAQ(false);
    setShowAnswers(false);
    setSearchQuery('');
    setSelectedQuestion(null);
    setIsOptionsOpen(false);
  };

  // Handle click away - only close if the click is outside both the search box and the popper
  const handleClickAway = (event) => {
    // Don't close if we're in the middle of selecting an item
    if (isSelectingRef.current) {
      return;
    }

    // Don't close if the click is inside the popper
    if (popperRef.current && popperRef.current.contains(event.target)) {
      return;
    }

    // Don't close if the click is inside the search box
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }

    handleCloseFAQ();
  };

  // Only show the answer popper when a question is selected and showAnswers is true
  const showAnswerPopper = Boolean(selectedQuestion && showAnswers);

  // Determine if we should apply the rounded top border radius
  const shouldRoundTopOnly = showAnswerPopper || isOptionsOpen;

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'sticky',
        top: { xs: 58, sm: 68 },
        zIndex: 1000,
        width: '100%',
        maxWidth: 1500,
        backgroundColor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        boxShadow: 1,
        py: 1,
        px: 3,
        borderRadius: shouldRoundTopOnly ? '32px 32px 0 0' : 8,
        transition: 'border-radius 0.2s ease',
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <Box ref={searchRef}>
            <Autocomplete
              fullWidth
              freeSolo
              options={searchQuery.trim() ? filteredFAQs : featuredFaqItems}
              getOptionLabel={(option) => {
                if (typeof option === 'string') return option;
                return option.question;
              }}
              filterOptions={(options) => options}
              value={selectedQuestion}
              inputValue={searchQuery}
              open={isOptionsOpen}
              onClose={() => setIsOptionsOpen(false)}
              onOpen={() => {
                setIsOptionsOpen(true);
                // If no search query, show featured items
                if (!searchQuery.trim() && featuredFaqItems.length > 0) {
                  setFilteredFAQs(featuredFaqItems);
                }
              }}
              forcePopupIcon={false}
              noOptionsText={t('boxettes.no_faq_results', 'Aucun résultat trouvé. Essayez une autre recherche.')}
              componentsProps={{
                popper: {
                  anchorEl: containerRef.current,
                  placement: 'bottom',
                  modifiers: [
                    {
                      name: 'sameWidth',
                      enabled: true,
                    },
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 0],
                      },
                    },
                  ],
                  sx: {
                    width: getContainerWidth(),
                    zIndex: 1200,
                  },
                },
                paper: {
                  elevation: 4,
                  sx: {
                    borderRadius: '0 0 32px 32px',
                    marginTop: '0 !important',
                    width: '100%',
                  },
                },
              }}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                isSelectingRef.current = true;

                setSelectedQuestion(newValue);

                if (newValue) {
                  // When a question is selected, show the answers instead of the dropdown
                  setShowFAQ(false);
                  setShowAnswers(true);
                  setFilteredFAQs([newValue]);
                } else {
                  setShowAnswers(false);
                }

                setTimeout(() => {
                  isSelectingRef.current = false;
                }, 300);
              }}
              onInputChange={(event, newValue) => {
                setSearchQuery(newValue);

                if (selectedQuestion && event) {
                  setSelectedQuestion(null);
                  setShowAnswers(false);
                }

                if (!newValue.trim()) {
                  // If empty, but we have featured items, still show the dropdown
                  if (featuredFaqItems.length > 0) {
                    setShowFAQ(true);
                    setIsOptionsOpen(true);
                    setFilteredFAQs(featuredFaqItems);
                  } else {
                    setShowFAQ(false);
                    setIsOptionsOpen(false);
                  }
                } else if (!selectedQuestion) {
                  // Only show the dropdown if no question is selected and there's text
                  setShowFAQ(true);
                  setIsOptionsOpen(true);
                }
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  placeholder={t('search_a_question')}
                  variant="standard"
                  onChange={handleSearchChange}
                  onFocus={handleSearchFocus}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    '& .MuiInput-root': {
                      padding: '4px 8px',
                      borderRadius: 1,
                    },
                  }}
                />
              )}
            />
          </Box>

          {/* Only render the answer popper when a question is selected */}
          {showAnswerPopper && (
            <Popper
              open={showAnswerPopper}
              anchorEl={containerRef.current}
              placement="bottom"
              transition
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 0],
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    padding: 8,
                  },
                },
              ]}
              sx={{
                width: getContainerWidth(),
                zIndex: 1200,
              }}
            >
              {({ TransitionProps }) => (
                <Fade
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...TransitionProps}
                  timeout={350}
                >
                  <Paper
                    ref={popperRef}
                    elevation={4}
                    sx={{
                      p: 2,
                      maxHeight: '60vh',
                      overflow: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '0 0 32px 32px',
                      borderTop: 'none',
                      width: '100%',
                    }}
                  >
                    <IconButton
                      onClick={handleCloseFAQ}
                      size="small"
                      sx={{ alignSelf: 'flex-end' }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Box>
                      {selectedQuestion && (
                        <Box
                          sx={{
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            {selectedQuestion.question}
                          </Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: selectedQuestion.answer || t('no_answer_found'),
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Fade>
              )}
            </Popper>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
