import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSigninCheck } from 'reactfire';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import {
  useLocation, Link,
} from 'react-router';
import IconButton from '@mui/material/IconButton';
import { menuPaths } from 'components/utils';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMenu from 'menu-actions/useMenu';
import Divider from '@mui/material/Divider';
import LanguageSelectToggle from 'components/account/LanguageSelectToggle';
import OrganizationsSelect from 'components/organization/OrganizationsSelect';
import useGlobal from 'global-state/store';
import { useLocalizedNavigate } from 'hooks/useLocalizedNavigate';
import { findPathByValue, getLocalizedPath } from 'routing/navigation';

export default function TopToolbar(props) {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const { menuActions: originalMenuActions } = useMenu();
  const [globalState] = useGlobal();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const location = useLocation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const isSmallScreen = useMemo(() => innerWidth < 600, [innerWidth]);
  const localizedNavigate = useLocalizedNavigate();

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuActions = useMemo(() => {
    if (!isSmallScreen
      || !signInCheckResult?.signedIn
      || menuPaths(location)
      || location.pathname.includes('account')) {
      return originalMenuActions;
    }

    const myAccountAction = {
      name: t('my_account'),
      id: 'my_account_action',
      callback: () => localizedNavigate('/account'),
    };

    const languageToggleAction = {
      name: <LanguageSelectToggle height={32} />,
      id: 'languageToggle',
      callback: () => { },
    };

    return {
      ...originalMenuActions,
      myAccountAction,
      languageToggleAction,
    };
  }, [isSmallScreen, location, localizedNavigate,
    originalMenuActions, signInCheckResult?.signedIn, t]);

  const hasActions = useMemo(() => Object.keys(menuActions).length > 0, [menuActions]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const displayOrganization = () => signInCheckResult?.signedIn === true
  && Object.entries(globalState.userOrganizations).length > 0;

  const displayMyAccount = () => {
    const accountPaths = Object.values(findPathByValue('account') || {});
    const isAccountPath = accountPaths.some((path) => location.pathname.includes(path.replace('/', '')));

    return signInCheckResult?.signedIn === true
      && (!isSmallScreen || !hasActions)
      && !isAccountPath;
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LogoOrBack />
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 0.5, sm: 1 },
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {(!hasActions || !isSmallScreen) && (<LanguageSelectToggle height={32} />)}
        {displayOrganization() && (<OrganizationsSelect small />)}

        {displayMyAccount() && (
          <Typography variant="h6">
            <IconButton
              aria-label="account"
              component={Link}
              to={getLocalizedPath('/account')}
              sx={{ p: 0, fontSize: 37 }}
              color="primary"
            >
              <AccountBox fontSize="inherit" />
            </IconButton>
          </Typography>
        )}
        {!(signInCheckResult?.signedIn === true) && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            component={Link}
            to={getLocalizedPath('/signin')}
            startIcon={<AccountCircle />}
          >
            {t('signin.login')}
          </Button>
        )}
        {hasActions && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />

      </Box>
    </Box>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions,
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      {Object.entries(actions).map((action) => (
        <MyMenuItem
          key={action[0]}
          title={action[1].name}
          callback={action[1].callback}
          handleMenuClose={handleMenuClose}
        />
      ))}
    </Menu>
  );
}

function MyMenuItem(props) {
  const { title, callback, handleMenuClose } = props;
  if (typeof title === 'string' && title.includes('divider')) {
    return <Divider />;
  }
  return (
    <MenuItem onClick={(event) => { handleMenuClose(); callback(); }}>
      {title}
    </MenuItem>
  );
}

function LogoOrBack(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const { returnAction, setreturnAction } = useMenu();

  useEffect(() => {
    setreturnAction(() => () => navigate(-1));
  }, [navigate, setreturnAction]);

  if (menuPaths(location)) {
    return <Box />;
  }

  return (
    <Button
      sx={{ color: 'text.primary' }}
      startIcon={<ChevronLeft />}
      onClick={returnAction}
    >
      {t('back')}
    </Button>
  );
}
