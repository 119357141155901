import React from 'react';

export const MenuStateContext = React.createContext();
export const MenuDispatchContext = React.createContext();

export function MenuProvider(props) {
  const [menuActions, dispatchMenuActions] = React.useReducer(reducerMenuActions, {});
  const [returnAction, setreturnAction] = React.useState(() => {});
  const { children } = props;

  function reducerMenuActions(currentState, dispatch) {
    let finalMenuActions;
    switch (dispatch.type) {
      case 'add':
        finalMenuActions = { ...currentState };
        dispatch.actions.forEach((action) => {
          const actionId = action.id || action.name;
          finalMenuActions[actionId] = { name: action.name, callback: action.callback };
        });
        return finalMenuActions;
      case 'set':
        finalMenuActions = {};
        dispatch.actions.forEach((action) => {
          const actionId = action.id || action.name;
          finalMenuActions[actionId] = { name: action.name, callback: action.callback };
        });
        return finalMenuActions;
      case 'clear':
        return {};
      default:
        throw new Error();
    }
  }

  const dispatchValue = React.useMemo(() => ({
    dispatchMenuActions,
    setreturnAction,
  }), [dispatchMenuActions, setreturnAction]);

  const stateValue = React.useMemo(() => ({
    menuActions,
    returnAction,
  }), [menuActions, returnAction]);

  return (
    <MenuDispatchContext.Provider value={dispatchValue}>
      <MenuStateContext.Provider value={stateValue}>
        {children}
      </MenuStateContext.Provider>
    </MenuDispatchContext.Provider>
  );
}

// Custom hooks to use the context
export function useMenuState() {
  const context = React.useContext(MenuStateContext);
  if (context === undefined) {
    throw new Error('useMenuState must be used within a MenuProvider');
  }
  return context;
}

export function useMenuDispatch() {
  const context = React.useContext(MenuDispatchContext);
  if (context === undefined) {
    throw new Error('useMenuDispatch must be used within a MenuProvider');
  }
  return context;
}
