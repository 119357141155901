// Map of old paths to their new localized equivalents
export const legacyRedirects = {
  '/boxettes': '/fr/',
  '/menu-account': '/fr/account',
  '/experts': '/fr/experts',
  '/oiml-publication': '/fr/publication-oiml',
  '/cgu': '/fr/conditions-generales-d-utilisation',
  '/cgv': '/fr/conditions-generales-de-vente',
  '/privacy-policy': '/fr/politique-de-confidentialite',
  '/boxettes/alco-conv': '/fr/conversion-alcoometrique',
  '/boxettes/vol-conv': '/fr/conversion-volume',
  '/boxettes/ullaging': '/fr/volume-fut-foudre',
  '/boxettes/mass-vol-conv': '/fr/conversion-masse-volume',
  '/boxettes/vol-pa-conv': '/fr/conversion-volume-alcool-pur',
  '/boxettes/vol-alco-conv': '/fr/conversion-alcool-avant-volume',
  '/boxettes/reduction-improvement': '/fr/reduction-remontage',
  '/boxettes/ebulliometer': '/fr/ebulliometre',
  '/boxettes/unit-conversions': '/fr/conversions-unites',
  '/boxettes/mass-vol-alco-conv': '/fr/conversion-alcool-avant-masse-volume',
  '/boxettes/energetic-value': '/fr/valeur-energetique',
  '/boxettes/mutage': '/fr/mutage',
  '/boxettes/pa-conv-with-dry-extract': '/fr/conversion-alcool-pur-extrait-sec',
  '/boxettes/alco-to-us-conv': '/fr/conversion-alcool-us',
  '/boxettes/ingredient-adjustments': '/fr/ajustements-ingredients',
  '/boxettes/dryExtractObscuration': '/fr/extrait-sec-obscuration',
  '/boxettes/flash-point': '/fr/point-eclair',
  '/boxettes/active-so2': '/fr/so2-actif',
  '/boxettes/active-so2/rectify': '/fr/so2-actif/rectifier',
  '/boxettes/free-so2': '/fr/so2-libre',

};

// Helper function to get the redirect target with proper language prefix
export function getRedirectPath(oldPath, language = 'fr') {
  const newPath = legacyRedirects[oldPath];
  if (!newPath) return null;

  return newPath;
}
