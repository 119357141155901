/* eslint-disable no-underscore-dangle */
if (typeof document !== 'undefined') {
  // This code only runs in the browser
  window.__PRERENDER_STATUS = {
    ready: false,
  };

  // Signal when the app is ready to be prerendered
  window.snapSaveState = () => ({
    ready: true,
  });
}
