/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';

export const pathTranslations = {
  subscriptions: {
    fr: 'abonnements',
    en: 'subscriptions',
    es: 'suscripciones',
    de: 'abonnements',
    pt: 'assinaturas',
    pl: 'subskrypcje',
  },
  cgu: {
    fr: 'conditions-generales-d-utilisation',
    en: 'general-terms-and-conditions',
    es: 'condiciones-generales-de-uso',
    de: 'allgemeine-nutzungsbedingungen',
    pt: 'termos-de-uso-geral',
    pl: 'ogolne-warunki-uzytkowania',
  },
  cgv: {
    fr: 'conditions-generales-de-vente',
    en: 'general-terms-of-sale',
    es: 'condiciones-generales-de-venta',
    de: 'allgemeine-verkaufsbedingungen',
    pt: 'termos-de-venda-geral',
    pl: 'ogolne-warunki-sprzedazy',
  },
  'cgu-cgv': {
    fr: 'conditions-generales-de-vente-et-conditions-generales-d-utilisation',
    en: 'general-terms-of-sale-and-general-terms-and-conditions',
    es: 'condiciones-generales-de-venta-y-condiciones-generales-de-uso',
    de: 'allgemeine-verkaufsbedingungen-und-allgemeine-nutzungsbedingungen',
    pt: 'termos-de-venda-geral-e-termos-de-uso-geral',
    pl: 'ogolne-warunki-sprzedazy-i-ogolne-warunki-uzytkowania',
  },
  'privacy-policy': {
    fr: 'politique-de-confidentialite',
    en: 'privacy-policy',
    es: 'politica-de-privacidad',
    de: 'datenschutzbestimmungen',
    pt: 'politica-de-privacidade',
    pl: 'polityka-prywatnosci',
  },
  'oiml-publication': {
    fr: 'publication-oiml',
    en: 'oiml-publication',
    es: 'publicacion-oiml',
    de: 'oiml-veroeffentlichung',
    pt: 'publicacao-oiml',
    pl: 'publikacja-oiml',
  },
  contact: {
    fr: 'contact',
    en: 'contact',
    es: 'contacto',
    de: 'kontakt',
    pt: 'contato',
    pl: 'kontakt',
  },
  signin: {
    fr: 'connexion',
    en: 'signin',
    es: 'iniciar-sesion',
    de: 'anmelden',
    pt: 'entrar',
    pl: 'zaloguj',
  },
  account: {
    fr: 'compte',
    en: 'account',
    es: 'cuenta',
    de: 'konto',
    pt: 'conta',
    pl: 'konto',
  },
  tips: {
    fr: 'astuces',
    en: 'tips',
    es: 'consejos',
    de: 'tipps',
    pt: 'dicas',
    pl: 'porady',
  },
  automated: {
    fr: 'automatise',
    en: 'automated',
    es: 'automatizado',
    de: 'automatisiert',
    pt: 'automatizado',
    pl: 'zautomatyzowane',
  },
  'about-us': {
    fr: 'a-propos',
    en: 'about-us',
    es: 'sobre-nosotros',
    de: 'ueber-uns',
    pt: 'sobre-nos',
    pl: 'o-nas',
  },
  experts: {
    fr: 'experts',
    en: 'experts',
    es: 'expertos',
    de: 'experten',
    pt: 'especialistas',
    pl: 'eksperci',
  },
  admin: {
    fr: 'admin',
    en: 'admin',
    es: 'admin',
    de: 'admin',
    pt: 'admin',
    pl: 'admin',
  },
  // BoxettesMenu routes
  'alco-conv': {
    fr: 'conversion-alcoometrique',
    en: 'alcohol-conversion',
    es: 'conversion-alcoholica',
    de: 'alkohol-umrechnung',
    pt: 'conversao-alcoolica',
    pl: 'przeliczanie-alkoholu',
  },
  'vol-conv': {
    fr: 'conversion-volume',
    en: 'volume-conversion',
    es: 'conversion-volumen',
    de: 'volumen-umrechnung',
    pt: 'conversao-volume',
    pl: 'przeliczanie-objetosci',
  },
  ullaging: {
    fr: 'volume-fut-foudre',
    en: 'cask-barrel-volume',
    es: 'volumen-barril',
    de: 'fass-volumen',
    pt: 'volume-barril',
    pl: 'objetosc-beczki',
  },
  'mass-vol-conv': {
    fr: 'conversion-masse-volume',
    en: 'mass-volume-conversion',
    es: 'conversion-masa-volumen',
    de: 'masse-volumen-umrechnung',
    pt: 'conversao-massa-volume',
    pl: 'przeliczanie-masa-objetosc',
  },
  'vol-pa-conv': {
    fr: 'conversion-volume-alcool-pur',
    en: 'pure-alcohol-volume-conversion',
    es: 'conversion-volumen-alcohol-puro',
    de: 'reinalkohol-volumen-umrechnung',
    pt: 'conversao-volume-alcool-puro',
    pl: 'przeliczanie-objetosc-czysty-alkohol',
  },
  'vol-alco-conv': {
    fr: 'conversion-alcool-avant-volume',
    en: 'alcohol-before-volume-conversion',
    es: 'conversion-alcohol-antes-volumen',
    de: 'alkohol-vor-volumen-umrechnung',
    pt: 'conversao-alcool-antes-volume',
    pl: 'konwersja-alkohol-przed-objetoscia',
  },
  'reduction-improvement': {
    fr: 'reduction-remontage',
    en: 'reduction-improvement',
    es: 'reduccion-mejora',
    de: 'reduktion-verbesserung',
    pt: 'reducao-melhoria',
    pl: 'redukcja-poprawa',
  },
  ebulliometer: {
    fr: 'ebulliometre',
    en: 'ebulliometer',
    es: 'ebulliometro',
    de: 'ebulliometer',
    pt: 'ebulliometro',
    pl: 'ebuliometr',
  },
  'unit-conversions': {
    fr: 'conversions-unites',
    en: 'unit-conversions',
    es: 'conversiones-unidades',
    de: 'einheiten-umrechnung',
    pt: 'conversoes-unidades',
    pl: 'konwersje-jednostek',
  },
  'mass-vol-alco-conv': {
    fr: 'conversion-alcool-avant-masse-volume',
    en: 'alcohol-before-mass-volume-conversion',
    es: 'conversion-alcohol-antes-masa-volumen',
    de: 'alkohol-vor-masse-volumen-umrechnung',
    pt: 'conversao-alcool-antes-massa-volume',
    pl: 'konwersja-alkohol-przed-masa-objetoscia',
  },
  'energetic-value': {
    fr: 'valeur-energetique',
    en: 'energy-value',
    es: 'valor-energetico',
    de: 'energiewert',
    pt: 'valor-energetico',
    pl: 'wartosc-energetyczna',
  },
  mutage: {
    fr: 'mutage',
    en: 'fortification',
    es: 'mutaje',
    de: 'aufspritung',
    pt: 'mutagem',
    pl: 'mutacja',
  },
  'pa-conv-with-dry-extract': {
    fr: 'conversion-alcool-pur-extrait-sec',
    en: 'pa-conv-with-dry-extract',
    es: 'conversion-pa-extracto-seco',
    de: 'pa-umrechnung-trockenextrakt',
    pt: 'conversao-pa-extrato-seco',
    pl: 'konwersja-pa-ekstrakt-suchy',
  },
  'alco-to-us-conv': {
    fr: 'conversion-alcool-us',
    en: 'alco-to-us-conv',
    es: 'conversion-alco-us',
    de: 'alko-us-umrechnung',
    pt: 'conversao-alco-us',
    pl: 'konwersja-alko-us',
  },
  'ingredient-adjustments': {
    fr: 'ajustements-ingredients',
    en: 'ingredient-adjustments',
    es: 'ajustes-ingredientes',
    de: 'zutatanpassungen',
    pt: 'ajustes-ingredientes',
    pl: 'dostosowanie-skladnikow',
  },
  dryExtractObscuration: {
    fr: 'extrait-sec-obscuration',
    en: 'dry-extract-obscuration',
    es: 'extracto-seco-obscuracion',
    de: 'trockenextrakt-verschleierung',
    pt: 'extrato-seco-obscuracao',
    pl: 'ekstrakt-suchy-zacieranie',
  },
  'flash-point': {
    fr: 'point-eclair',
    en: 'flash-point',
    es: 'punto-inflamacion',
    de: 'flammpunkt',
    pt: 'ponto-fulgor',
    pl: 'temperatura-zaplonu',
  },
  'active-so2': {
    fr: 'so2-actif',
    en: 'active-so2',
    es: 'so2-activo',
    de: 'aktives-so2',
    pt: 'so2-ativo',
    pl: 'so2-aktywny',
  },
  rectify: {
    fr: 'rectifier',
    en: 'rectify',
    es: 'rectificar',
    de: 'korrigieren',
    pt: 'retificar',
    pl: 'koryguj',
  },
  'free-so2': {
    fr: 'so2-libre',
    en: 'free-so2',
    es: 'so2-libre',
    de: 'freies-so2',
    pt: 'so2-livre',
    pl: 'so2-wolny',
  },
  'recipe-calculations': {
    fr: 'calcul-recettes',
    en: 'recipe-calculations',
    es: 'calculo-recetas',
    de: 'rezept-berechnungen',
    pt: 'calculo-receitas',
    pl: 'obliczenia-receptur',
  },
};

// Helper function to find path by value
export function findPathByValue(searchValue) {
  // Normalize the search value by removing leading slash if present
  const normalizedSearch = searchValue.startsWith('/') ? searchValue.slice(1) : searchValue;
  for (const [basePath, translations] of Object.entries(pathTranslations)) {
    // Check if it matches the base path
    if (basePath === normalizedSearch) {
      return basePath;
    }
    // Check if it matches any of the translated values
    const translatedValues = Object.values(translations);
    if (translatedValues.includes(normalizedSearch)) {
      return basePath;
    }
  }
  return searchValue;
}

// Helper function that only handles translation without prefix
export function translatePathSegment(segment) {
  const normalizedSegment = segment.startsWith('/') ? segment.slice(1) : segment;
  const basePath = findPathByValue(normalizedSegment);
  return pathTranslations[basePath]?.[i18next.language] || basePath;
}

export function getLocalizedPath(path, isAbsolute = false) {
  const currentLanguage = i18next.language;
  const hasLeadingSlash = path.startsWith('/');

  const isRelative = !hasLeadingSlash && !isAbsolute;
  if (isRelative) {
    // Split into relative part and the rest
    const match = path.match(/^(?:\.\.\/|\.\/)/);
    const relativePart = match ? match[0] : '';
    const remainingPath = path.slice(relativePart.length);

    if (relativePart) {
      // If we have a relative prefix (../ or ./)
      const segments = remainingPath.split('/');
      const translatedSegments = segments.map((segment) => translatePathSegment(segment));
      return relativePart + translatedSegments.join('/');
    }
    // Simple relative path without prefix
    return translatePathSegment(path);
  }

  // Handle absolute paths (existing logic)
  const normalizedPath = path.replace(/^\/+|\/+$/g, '');

  if (normalizedPath.includes('/')) {
    const segments = normalizedPath.split('/');
    const processedSegments = segments.map((segment) => translatePathSegment(segment));
    return `/${currentLanguage}/${processedSegments.join('/')}`;
  }

  const translatedPath = translatePathSegment(normalizedPath);
  return `/${currentLanguage}/${translatedPath}`;
}
