import React, { useEffect, useState } from 'react';
import useGlobal from 'global-state/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Trans } from 'react-i18next';
import { Link } from 'react-router';
import MUILink from '@mui/material/Link';
import { getLocalizedPath } from 'routing/navigation';

export default function GlobalSnackbar() {
  const [globalState, globalActions] = useGlobal();
  const [autoHideDuration, setAutoHideDuration] = useState(null);
  const [isNonClosable, setIsNonClosable] = useState(true);

  useEffect(() => {
    if (globalState.globalSnackbar.display) {
      setIsNonClosable(true);
      const timer = setTimeout(() => {
        setAutoHideDuration(15000);
        setIsNonClosable(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [globalState.globalSnackbar.display]);

  const handleClose = (event, reason) => {
    if (isNonClosable && reason === 'clickaway') {
      return;
    }
    globalActions.closeSnackbar();
  };

  // Render message with Trans component if useTransComponent is true
  const renderMessage = () => {
    if (globalState.globalSnackbar.linkMessageKey) {
      const linkComponent = (
        <MUILink
          component={Link}
          to={getLocalizedPath(globalState.globalSnackbar.linkPath)}
          color="primary"
          sx={{ fontWeight: 'bold' }}
        />
      );

      return (
        <Trans
          i18nKey={globalState.globalSnackbar.linkMessageKey}
          values={globalState.globalSnackbar.linkMessageProps}
          components={{ 1: linkComponent }}
        />
      );
    }

    return globalState.globalSnackbar.message;
  };

  return (
    <Snackbar
      open={globalState.globalSnackbar.display}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity={globalState.globalSnackbar.severity}
        sx={{ p: 5, boxShadow: 3 }}
        action={(
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        {renderMessage()}
      </Alert>
    </Snackbar>
  );
}
