import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxetteForm } from 'components/boxettes/BoxetteForm';
import validationRectifySo2Schema from './ValidationRectifySO2Schema';
import decimalsMask from './DecimalsMask';
import RectifySO2InnerForm from './RectifySO2InnerForm';

export default function RectifySO2Form(props) {
  const { t } = useTranslation();
  const {
    rectifySO2,
    activeSO2Values,
    convertActiveSO2,
    fromActiveSO2,
    freeBoxette,
  } = props;

  const boxetteCall = async (values) => {
    let freeSO2ConcentrationOutResult;
    if (fromActiveSO2) {
      freeSO2ConcentrationOutResult = await convertActiveSO2({
        abvAt20cValue: activeSO2Values.abvAt20cValue,
        pHValue: activeSO2Values.pHValue,
        temperatureInValue: activeSO2Values.temperatureInValue,
        activeSO2ConcentrationInValue: values.freeOrActiveSO2ConcentrationOutValue,

        abvAt20cUnit: activeSO2Values.abvAt20cUnit,
        pHUnit: activeSO2Values.pHUnit,
        temperatureInUnit: activeSO2Values.temperatureInUnit,
        activeSO2ConcentrationInUnit: values.freeSO2ConcentrationInUnit,
        freeOrActiveSO2ConcentrationOutUnit: activeSO2Values.activeSO2ConcentrationOutUnit,

        abvAt20cPrecision: decimalsMask[activeSO2Values.abvAt20cUnit],
        pHPrecision: decimalsMask[activeSO2Values.pHUnit],
        temperatureInPrecision: decimalsMask[activeSO2Values.temperatureInUnit],
        activeSO2ConcentrationInPrecision: decimalsMask[values.freeSO2ConcentrationInUnit],
      });
    }

    let roundedRate = values.combinedRateValue / 100;
    roundedRate = roundedRate.toFixed(3);

    const rectificationResults = await rectifySO2(
      {
        volumeInValue: values.volumeInValue,
        freeSO2ConcentrationInValue: fromActiveSO2
          ? activeSO2Values.freeSO2ConcentrationInValue
          : values.freeSO2ConcentrationInValue,
        freeSO2ConcentrationOutValue: fromActiveSO2
          ? freeSO2ConcentrationOutResult.freeSO2.value
          : values.freeOrActiveSO2ConcentrationOutValue,
        combinedRateValue: roundedRate,
        totalSO2ConcentrationInValue: values.totalSO2ConcentrationInValue,

        volumeInUnit: values.volumeInUnit,
        volumeOutUnit: values.volumeOutUnit,
        massOutUnit: values.massOutUnit,
        freeSO2ConcentrationInUnit: fromActiveSO2
          ? activeSO2Values.freeSO2ConcentrationInUnit
          : values.freeSO2ConcentrationInUnit,
        freeSO2ConcentrationOutUnit: fromActiveSO2
          ? freeSO2ConcentrationOutResult.freeSO2.unit
          : values.freeOrActiveSO2ConcentrationOutUnit,
        combinedRateUnit: '',
        totalSO2ConcentrationInUnit: values.totalSO2ConcentrationInUnit,
        totalSO2ConcentrationOutUnit: values.totalSO2ConcentrationOutUnit,
        SO2ToAddUnit: values.SO2ToAddUnit,

        volumeInPrecision: decimalsMask[values.volumeInUnit],
        freeSO2ConcentrationInPrecision: fromActiveSO2
          ? decimalsMask[activeSO2Values.freeSO2ConcentrationInUnit]
          : decimalsMask[values.freeSO2ConcentrationInUnit],
        freeSO2ConcentrationOutPrecision: fromActiveSO2
          ? freeSO2ConcentrationOutResult.freeSO2.precision
          : decimalsMask[values.freeOrActiveSO2ConcentrationOutUnit],
        combinedRatePrecision: 3,
        totalSO2ConcentrationInPrecision: decimalsMask[values.totalSO2ConcentrationInUnit],
      },
    );

    return { ...freeSO2ConcentrationOutResult, ...rectificationResults };
  };

  const defaultFormValues = {
    volumeInValue: '',
    freeSO2ConcentrationInValue: '',
    freeOrActiveSO2ConcentrationOutValue: '',
    combinedRateValue: 66.7,
    totalSO2ConcentrationInValue: '',
    volumeInUnit: 'hL',
    volumeOutUnit: 'L',
    massOutUnit: 'kg',
    freeSO2ConcentrationInUnit: 'mg / L',
    freeOrActiveSO2ConcentrationOutUnit: 'mg / L',
    totalSO2ConcentrationInUnit: 'mg / L',
    totalSO2ConcentrationOutUnit: 'mg / L',
    SO2ToAddUnit: 'g / hL',
    combinedRateUnit: '',
  };

  return (
    <BoxetteForm
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="rectify_so2_form"
      validationSchema={() => validationRectifySo2Schema(t, fromActiveSO2)}
      form={(
        <RectifySO2InnerForm fromActiveSO2={fromActiveSO2} />
      )}
      freeBoxette={freeBoxette}
    />
  );
}
