import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { nanoid } from 'nanoid';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Checkbox,
} from '@mui/material';
import {
  DataGrid,
  GridFooterContainer,
  GridActionsCellItem,
  useGridApiRef,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { BoxetteFormContext } from '../../BoxetteForm';
import { LabelInputSelect, NumberInput } from '../../BoxetteInputs';
import useColumns, { cellStyles } from '../constants/columns';
import RecipeResults from './RecipeResults';
import { decimalsMask, defaultFormValues } from '../constants/form';

function Footer({ onAdd }) {
  const { t } = useTranslation();
  return (
    <GridFooterContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={onAdd}
        sx={{ margin: '8px' }}
      >
        {t('add')}
      </Button>
      <Typography variant="body2" color="text.secondary" sx={{ mr: '8px' }}>
        <Trans i18nKey="recipe_calculations.click_info" components={{ 1: <strong /> }} />
      </Typography>
    </GridFooterContainer>
  );
}

export default function BoxetteFormContent() {
  const { t } = useTranslation();
  const {
    formik, handleChange, handleBlur, backupSave,
  } = useContext(BoxetteFormContext);
  const { ingredientsColumns } = useColumns();
  const gridRef = useGridApiRef();

  const handleIngredientsChange = (newRow) => {
    const updatedIngredients = formik.values.ingredients.map((row) => (
      row.id === newRow.id ? newRow : row
    ));
    formik.setFieldValue('ingredients', updatedIngredients);
    return newRow;
  };

  const addIngredient = () => {
    const newIngredient = {
      id: nanoid(),
      name: '',
      trueAbv: '',
      sugarDensity: '',
      minVolume: '',
      minVolumeUnit: 'L',
      maxVolume: '',
      maxVolumeUnit: 'L',
      optimization: 'none',
    };
    formik.setFieldValue('ingredients', [...formik.values.ingredients, newIngredient]);
  };

  const removeIngredient = (id) => {
    const updatedIngredients = formik.values.ingredients.filter(
      (ingredient) => ingredient.id !== id,
    );
    formik.setFieldValue('ingredients', updatedIngredients);
  };

  const getActionColumn = () => ({
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 75,
    getActions: ({ id }) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => removeIngredient(id)}
      />,
    ],
  });

  const columns: GridColDef[] = [
    ...ingredientsColumns,
    getActionColumn(),
  ];

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t('recipe_calculations.title')}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: 'ourGreen.main',
          borderRadius: '5px',
          padding: '5px 20px',
          zIndex: 2,
          boxShadow: 1,
          alignSelf: 'center',
          mb: 3,
          mt: -2,
        }}
      >
        Beta test
      </Typography>

      <Box mb={3}>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
          {t('recipe_calculations.expected_parameters')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <LabelInputSelect
              label={t('recipe_calculations.expected_abv')}
              input={(
                <NumberInput
                  name="expectedParameters.trueAbv"
                  decimalScale={decimalsMask['expectedParameters.trueAbv']}
                  value={formik.values.expectedParameters.trueAbv}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, 'expectedParameters.trueAbv')}
                  error={!!(formik.touched.expectedParameters?.trueAbv && formik.errors.expectedParameters?.trueAbv)}
                />
              )}
              select="%vol"
              error={formik.touched.expectedParameters?.trueAbv && formik.errors.expectedParameters?.trueAbv}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelInputSelect
              label={t('recipe_calculations.expected_sugar_density')}
              input={(
                <NumberInput
                  name="expectedParameters.sugarDensity"
                  decimalScale={decimalsMask['expectedParameters.sugarDensity']}
                  value={formik.values.expectedParameters.sugarDensity}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, 'expectedParameters.sugarDensity')}
                  error={!!(formik.touched.expectedParameters?.sugarDensity
                    && formik.errors.expectedParameters?.sugarDensity)}
                />
              )}
              select="g/L"
              error={formik.touched.expectedParameters?.sugarDensity && formik.errors.expectedParameters?.sugarDensity}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LabelInputSelect
              label={t('recipe_calculations.expected_volume')}
              input={(
                <NumberInput
                  name="expectedParameters.volume"
                  decimalScale={decimalsMask['expectedParameters.volume']}
                  value={formik.values.expectedParameters.volume}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, 'expectedParameters.volume')}
                  error={!!(formik.touched.expectedParameters?.volume
                    && formik.errors.expectedParameters?.volume)}
                />
              )}
              select="L"
              error={formik.touched.expectedParameters?.volume
                && formik.errors.expectedParameters?.volume}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {t('recipe_calculations.ingredients')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('recipe_calculations.info_true_abv_grid')}
        </Typography>
        <Paper sx={{
          width: '100%',
          textAlign: 'left',
          maxWidth: { xs: '95vw', md: '75vw' },
        }}
        >
          <DataGrid
            autoHeight
            apiRef={gridRef}
            rows={formik.values.ingredients}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            processRowUpdate={handleIngredientsChange}
            getRowId={(row) => row.id}
            columnHeaderHeight={90}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            slots={{
              footer: Footer,
            }}
            slotProps={{
              footer: {
                onAdd: addIngredient,
              },
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '2px solid rgba(224, 224, 224, 1)',
              },
              '& .baseCell': cellStyles.baseCell,
              '& .minVolumeCell': cellStyles.minVolumeCell,
              '& .maxVolumeCell': cellStyles.maxVolumeCell,
              '& .optimizationCell': cellStyles.optimizationCell,
              '& .error': {
                outline: '1px dashed #d32f2f',
                outlineOffset: '-2px',
              },
            }}
            getCellClassName={(params) => {
              const rowErrors = formik.errors.ingredients?.[
                formik.values.ingredients.findIndex((ing) => ing.id === params.id)
              ];
              const hasError = rowErrors && rowErrors[params.field];
              const baseClassName = params.colDef.cellClassName || '';
              return hasError ? `${baseClassName} error` : baseClassName;
            }}
          />
        </Paper>
        {formik.touched.ingredients && formik.errors.ingredients && (
          <Typography color="error" variant="body2" sx={{ mt: 1, display: 'block' }}>
            {Array.isArray(formik.errors.ingredients)
              ? formik.errors.ingredients.map((error, index) => {
                if (!error) return null;
                if (typeof error === 'string') {
                  return (
                    <span key={`${formik.values.ingredients[index]?.id}-error`}>
                      {`${t('row')} ${index + 1}: ${error}`}
                      <br />
                    </span>
                  );
                }
                const errorMessages = Object.entries(error).map(([field, message]) => {
                  const columnHeader = ingredientsColumns.find((col) => col.field === field)?.headerName;
                  return `${columnHeader}: ${message}`;
                });
                return (
                  <span key={`${formik.values.ingredients[index]?.id}-errors`}>
                    {`${t('row')} ${index + 1}, ${errorMessages.join('. ')}`}
                    <br />
                  </span>
                );
              })
              : formik.errors.ingredients}
          </Typography>
        )}
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ alignSelf: 'flex-start' }}
          onClick={() => {
            formik.resetForm({ values: defaultFormValues });
          }}
        >
          {t('reset')}
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Checkbox
            name="useCrystallizedSugar"
            checked={formik.values.useCrystallizedSugar === true}
            onChange={(e) => formik.setFieldValue('useCrystallizedSugar', e.target.checked)}
            inputProps={{ 'aria-label': 'use crystallized sugar' }}
          />
          <Typography>{t('recipe_calculations.use_crystallized_sugar')}</Typography>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
        gap: 2,
        flexDirection: 'row',
        alignItems: 'stretch',
      }}
      >
        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
        >
          {t('forms.calcul')}
        </LoadingButton>
        <Button
          color="secondary"
          variant="contained"
          onClick={backupSave}
          size="large"
        >
          <SaveAsIcon />
        </Button>
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
        {t('recipe_calculations.info_no_contraction_calcul')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <RecipeResults expectedParameters={formik.values.expectedParameters} />
      </Box>

      <Paper
        elevation={0}
        sx={{
          display: 'flex', justifyContent: 'center', mt: 2, p: 2,
        }}
      >
        <Typography variant="body1" color="text.secondary">
          {t('recipe_calculations.optimix_info')}
        </Typography>
      </Paper>
    </Box>
  );
}
